function e(e) {
  if (e.__esModule) return e;
  var t = e.default;
  if ("function" == typeof t) {
    var r = function e() {
      return this instanceof e ? Reflect.construct(t, arguments, this.constructor) : t.apply(this, arguments);
    };
    r.prototype = t.prototype;
  } else r = {};
  return Object.defineProperty(r, "__esModule", {
    value: !0
  }), Object.keys(e).forEach(function (t) {
    var n = Object.getOwnPropertyDescriptor(e, t);
    Object.defineProperty(r, t, n.get ? n : {
      enumerable: !0,
      get: function () {
        return e[t];
      }
    });
  }), r;
}
export { e as getAugmentedNamespace };