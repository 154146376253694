import { sendAndHandleStatusAndReason as e } from "../internal/communication.js";
import { createTeamsDeepLinkForCalendar as t } from "../internal/deepLinkUtilities.js";
import { ensureInitialized as n } from "../internal/internalAPIs.js";
import { getApiVersionTag as r } from "../internal/telemetry.js";
import { FrameContexts as o } from "./constants.js";
import { runtime as i } from "./runtime.js";
var m;
!function (m) {
  function a() {
    return !(!n(i) || !i.supports.calendar);
  }
  m.openCalendarItem = function (t) {
    return new Promise(m => {
      if (n(i, o.content), !a()) throw new Error("Not supported");
      if (!t.itemId || !t.itemId.trim()) throw new Error("Must supply an itemId to openCalendarItem");
      m(e(r("v2", "calendar.openCalendarItem"), "calendar.openCalendarItem", t));
    });
  }, m.composeMeeting = function (m) {
    return new Promise(s => {
      if (n(i, o.content), !a()) throw new Error("Not supported");
      const p = r("v2", "calendar.composeMeeting");
      i.isLegacyTeams ? s(e(p, "executeDeepLink", t(m.attendees, m.startTime, m.endTime, m.subject, m.content))) : s(e(p, "calendar.composeMeeting", m));
    });
  }, m.isSupported = a;
}(m || (m = {}));
export { m as calendar };