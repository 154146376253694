import { createAction, props } from '@ngrx/store';
import { ModifiedAccountInfo, TeamsEnvironment, TenantAccount } from './auth.models';

export const msalLogin = createAction(
    '[Auth] Msal login',
    props<{ loginHint?: string; scopes?: string[]; redirectStartPage?: string }>()
);

export const msalLoginSuccess = createAction('[Auth] Msal login success', props<{ data: ModifiedAccountInfo }>());

export const msalLoginFailure = createAction('[Auth] Msal login failure', props<{ error: unknown }>());

export const msalLoadLoggedInUser = createAction(
    '[Auth] Msal set logged in user',
    props<{ data: ModifiedAccountInfo }>()
);

export const msalLogout = createAction('[Auth] Msal logout');

export const setIsTeams = createAction('[Auth] Set IsTeams', props<{ isTeams: boolean }>());

export const setTeamsEnvironment = createAction(
    '[Auth] Set Teams Environment',
    props<{ teamsEnvironment: TeamsEnvironment }>()
);

export const loadTenant = createAction('[Auth] Load tenant');
export const loadTenantSuccess = createAction('[Auth] Load tenant success', props<{ tenantAccount: TenantAccount }>());
export const loadTenantFailure = createAction('[Auth] Load tenant failure', props<{ error: unknown }>());
export const setIsDemo = createAction('[Auth] Set IsDemo', props<{ isDemo: boolean }>());

export const startTeamsInteraction = createAction('[Auth] Start Teams Interaction');
export const endTeamsInteraction = createAction('[Auth] End Teams Interaction');
