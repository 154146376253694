import { Injectable } from '@angular/core';
import { DataServiceResponse, IDataService } from './data.service';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../reducers';
import { AppDB } from './db.service';
import { Observable, combineLatest, forkJoin, from, map, mergeMap, of, switchMap, take, tap } from 'rxjs';
import { TocService } from './toc.service';
import { DataSourceType } from './data-service.factory';
import { DirectoryUser, chartItemToDirectoryUser } from '../data/data.models';
import * as dataActions from '../data/data.actions';
import { DisplayNameSplit } from '../settings/settings.models';
import { settingsFeature } from '../settings/settings.reducer';

@Injectable({ providedIn: 'root' })
export class ChartService implements IDataService {
    constructor(private store: Store, private db: AppDB, private tocService: TocService) {}
    getData(): Observable<DataServiceResponse> {
        let progress = 100;
        const cached = this.db.getUsers().pipe(
            map((cachedUsers) => {
                const result: DataServiceResponse = {
                    users: cachedUsers,
                    dataSourceType: DataSourceType.Chart
                };
                return result;
            })
        );

        const users = combineLatest([
            this.store.pipe(select(fromRoot.selectSelectedDataSourceChartId), take(1)),
            this.store.pipe(select(settingsFeature.selectDisplayNameSplit), take(1))
        ]).pipe(
            switchMap(([chartId, displayNameSplit]) => this.tocService.fetchChartData(chartId).pipe(
                map(data => data.map(item => chartItemToDirectoryUser(item, displayNameSplit, data)))
            )),
            mergeMap((data) => from(this.db.instance.users.bulkPut(data))),
            switchMap(() => this.db.getUsers()),
            map<DirectoryUser[], DataServiceResponse>((users) => ({
                users: users,
                dataSourceType: DataSourceType.Graph,
                isRefreshNeeded: false
            })),
            tap(() => this.store.dispatch(dataActions.loadUsersProgress({ progress: 100 }))),
            tap(() => this.store.dispatch(dataActions.hasRefreshedData())),
            map((response) => response)
        );

        const res = cached.pipe(
            switchMap((cachedResult) => {
                if (cachedResult.users.length === 0) {
                    console.log('CACHE IS EMPTY, GETTING USERS FROM GRAPH!');
                    progress = 0;
                    this.store.dispatch(dataActions.loadUsersProgress({ progress }));
                    return users;
                }
                this.store.dispatch(dataActions.loadUsersProgress({ progress: 100 }));
                return of(cachedResult);
            })
        );

        return res;
    }

    reloadData(): Observable<DataServiceResponse> {
        return forkJoin([this.db.clearUsers(), this.db.clearCategories(), this.db.clearUserPhotos()]).pipe(
            switchMap(() => this.getData())
        );
    }

    getIndividualUsersData(users: DirectoryUser[]) {
        return of([]);
    }

    getDataSourceType(): DataSourceType {
        return DataSourceType.Chart;
    }
}
