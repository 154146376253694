import { __awaiter as e } from "../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.0_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { getLogger as n, isFollowingApiVersionTagFormat as i, getApiVersionTag as t } from "./telemetry.js";
import { latestRuntimeApiVersion as o } from "../public/runtime.js";
import { version as s } from "../public/version.js";
import { GlobalVars as r } from "./globalVars.js";
import { callHandler as a } from "./handlers.js";
import { serializeMessageRequest as d, deserializeMessageResponse as c, deserializeMessageRequest as u, serializeMessageResponse as l } from "./messageObjects.js";
import { tryPolyfillWithNestedAppAuthBridge as g } from "./nestedAppAuthUtils.js";
import { ssrSafeWindow as p } from "./utils.js";
import { UUID as f } from "./uuidObject.js";
import { validateOrigin as w } from "./validOrigins.js";
const m = n("communication");
class h {}
class W {}
function v(n, i) {
  if (W.messageListener = n => function (n) {
    return e(this, void 0, void 0, function* () {
      if (!n || !n.data || "object" != typeof n.data) return void C("Unrecognized message format received by app, message being ignored. Message: %o", n);
      const e = n.source || n.originalEvent && n.originalEvent.source,
        i = n.origin || n.originalEvent && n.originalEvent.origin;
      return N(e, i).then(o => {
        o ? (function (e, n) {
          r.isFramelessWindow || h.parentWindow && !h.parentWindow.closed && e !== h.parentWindow ? h.childWindow && !h.childWindow.closed && e !== h.childWindow || (h.childWindow = e, h.childOrigin = n) : (h.parentWindow = e, h.parentOrigin = n);
          h.parentWindow && h.parentWindow.closed && (h.parentWindow = null, h.parentOrigin = null);
          h.childWindow && h.childWindow.closed && (h.childWindow = null, h.childOrigin = null);
          K(h.parentWindow), K(h.childWindow);
        }(e, i), e === h.parentWindow ? _(n) : e === h.childWindow && function (e) {
          if ("id" in e.data && "func" in e.data) {
            const n = u(e.data),
              [i, o] = a(n.func, n.args);
            i && void 0 !== o ? (z("Returning message %s from child back to child, action: %s.", Z(n), n.func), X(n.id, n.uuid, Array.isArray(o) ? o : [o])) : (z("Relaying message %s from child to parent, action: %s. Relayed message will have a new id.", Z(n), n.func), S(t("v2", "tasks.startTask"), n.func, n.args, (...e) => {
              if (h.childWindow) {
                const i = e.pop();
                z("Message from parent being relayed to child, id: %s", Z(n)), X(n.id, n.uuid, e, i);
              }
            }));
          }
        }(n)) : C("Message being ignored by app because it is either coming from the current window or a different window with an invalid origin, message: %o, source: %o, origin: %o", n, e, i);
      });
    });
  }(n), h.currentWindow = h.currentWindow || p(), h.parentWindow = h.currentWindow.parent !== h.currentWindow.self ? h.currentWindow.parent : h.currentWindow.opener, h.topWindow = h.currentWindow.top, (h.parentWindow || n) && h.currentWindow.addEventListener("message", W.messageListener, !1), !h.parentWindow) {
    const e = h.currentWindow;
    if (!e.nativeInterface) return Promise.reject(new Error("Initialization Failed. No Parent window found."));
    r.isFramelessWindow = !0, e.onNativeMessage = _;
  }
  try {
    return h.parentOrigin = "*", T(i, "initialize", [s, o, n]).then(([e, n, i, t]) => (g(t, h.currentWindow, {
      onMessage: Q,
      sendPostMessage: R
    }), {
      context: e,
      clientType: n,
      runtimeConfig: i,
      clientSupportedSDKVersion: t
    }));
  } finally {
    h.parentOrigin = null;
  }
}
function M() {
  h.currentWindow && h.currentWindow.removeEventListener("message", W.messageListener, !1), h.currentWindow = null, h.parentWindow = null, h.parentOrigin = null, h.childWindow = null, h.childOrigin = null, W.parentMessageQueue = [], W.childMessageQueue = [], W.nextMessageId = 0, W.callbacks.clear(), W.promiseCallbacks.clear(), W.portCallbacks.clear(), W.legacyMessageIdsToUuidMap = {};
}
function b(e, n, ...i) {
  return T(e, n, i).then(([e]) => e);
}
function y(e, n, ...i) {
  return T(e, n, i).then(([e, n]) => {
    if (!e) throw new Error(n);
  });
}
function k(e, n, i, ...t) {
  return T(e, n, t).then(([e, n]) => {
    if (!e) throw new Error(n || i);
  });
}
function I(e, n, ...i) {
  return T(e, n, i).then(([e, n]) => {
    if (e) throw e;
    return n;
  });
}
function T(e, n, t = void 0) {
  if (!i(e)) throw Error(`apiVersionTag: ${e} passed in doesn't follow the pattern starting with 'v' followed by digits, then underscore with words, please check.`);
  return new Promise(i => {
    const o = A(e, n, t);
    var s;
    i((s = o.uuid, new Promise(e => {
      W.promiseCallbacks.set(s, e);
    })));
  });
}
function O(e, n, t = void 0) {
  if (!i(e)) throw Error(`apiVersionTag: ${e} passed in doesn't follow the pattern starting with 'v' followed by digits, then underscore with words, please check.`);
  const o = A(e, n, t);
  return s = o.uuid, new Promise((e, n) => {
    W.portCallbacks.set(s, (i, t) => {
      i instanceof MessagePort ? e(i) : n(t && t.length > 0 ? t[0] : new Error("Host responded without port or error details."));
    });
  });
  var s;
}
function S(e, n, t, o) {
  let s;
  if (t instanceof Function ? o = t : t instanceof Array && (s = t), !i(e)) throw Error(`apiVersionTag: ${e} passed in doesn't follow the pattern starting with 'v' followed by digits, then underscore with words, please check.`);
  const r = A(e, n, s);
  o && W.callbacks.set(r.uuid, o);
}
W.parentMessageQueue = [], W.childMessageQueue = [], W.topMessageQueue = [], W.nextMessageId = 0, W.callbacks = new Map(), W.promiseCallbacks = new Map(), W.portCallbacks = new Map(), W.legacyMessageIdsToUuidMap = {};
const j = m.extend("sendNestedAuthRequestToTopWindow");
function R(e) {
  const n = j,
    i = h.topWindow,
    t = function (e) {
      const n = W.nextMessageId++,
        i = new f();
      return W.legacyMessageIdsToUuidMap[n] = i, {
        id: n,
        uuid: i,
        func: "nestedAppAuth.execute",
        timestamp: Date.now(),
        args: [],
        data: e
      };
    }(e);
  return n("Message %s information: %o", Z(t), {
    actionName: t.func
  }), P(i, t);
}
const x = m.extend("sendRequestToTargetWindowHelper");
function P(e, n) {
  const i = x,
    t = J(e),
    o = d(n);
  if (r.isFramelessWindow) h.currentWindow && h.currentWindow.nativeInterface && (i("Sending message %s to %s via framelessPostMessage interface", Z(o), t), h.currentWindow.nativeInterface.framelessPostMessage(JSON.stringify(o)));else {
    const s = H(e);
    e && s ? (i("Sending message %s to %s via postMessage", Z(o), t), e.postMessage(o, s)) : (i("Adding message %s to %s message queue", Z(o), t), D(e).push(n));
  }
  return n;
}
const E = m.extend("sendMessageToParentHelper");
function A(e, n, i) {
  const t = E,
    o = h.parentWindow,
    s = function (e, n, i) {
      const t = W.nextMessageId++,
        o = new f();
      return W.legacyMessageIdsToUuidMap[t] = o, {
        id: t,
        uuid: o,
        func: n,
        timestamp: Date.now(),
        args: i || [],
        apiVersionTag: e
      };
    }(e, n, i);
  return t("Message %s information: %o", Z(s), {
    actionName: n,
    args: i
  }), P(o, s);
}
const C = m.extend("processIncomingMessage");
const U = m.extend("processAuthBridgeMessage");
function Q(e, n) {
  var i, t;
  const o = U;
  if (!e || !e.data || "object" != typeof e.data) return void o("Unrecognized message format received by app, message being ignored. Message: %o", e);
  const {
      args: s
    } = e.data,
    [, r] = null != s ? s : [],
    a = (() => {
      try {
        return JSON.parse(r);
      } catch (e) {
        return null;
      }
    })();
  if (!a || "object" != typeof a || "NestedAppAuthResponse" !== a.messageType) return void o("Unrecognized data format received by app, message being ignored. Message: %o", e);
  const d = e.source || (null === (i = null == e ? void 0 : e.originalEvent) || void 0 === i ? void 0 : i.source),
    c = e.origin || (null === (t = null == e ? void 0 : e.originalEvent) || void 0 === t ? void 0 : t.origin);
  d ? N(d, c) ? (h.topWindow && !h.topWindow.closed && d !== h.topWindow || (h.topWindow = d, h.topOrigin = c), h.topWindow && h.topWindow.closed && (h.topWindow = null, h.topOrigin = null), K(h.topWindow), n(r)) : o("Message being ignored by app because it is either coming from the current window or a different window with an invalid origin") : o("Message being ignored by app because it is coming for a target that is null");
}
const F = m.extend("shouldProcessIncomingMessage");
function N(n, i) {
  return e(this, void 0, void 0, function* () {
    if (h.currentWindow && n === h.currentWindow) return F("Should not process message because it is coming from the current window"), !1;
    if (h.currentWindow && h.currentWindow.location && i && i === h.currentWindow.location.origin) return !0;
    {
      let e;
      try {
        e = new URL(i);
      } catch (e) {
        return F("Message has an invalid origin of %s", i), !1;
      }
      const n = yield w(e);
      return n || F("Message has an invalid origin of %s", i), n;
    }
  });
}
const $ = m.extend("handleIncomingMessageFromParent");
function L(e, n) {
  if (n) {
    const i = [...e].find(([e, i]) => e.toString() === n.toString());
    if (i) return i[0];
  }
}
function V(e, n) {
  const i = L(n, e.uuid);
  i && n.delete(i), e.uuid ? W.legacyMessageIdsToUuidMap = {} : delete W.legacyMessageIdsToUuidMap[e.id];
}
function _(e) {
  const n = $;
  if ("id" in e.data && "number" == typeof e.data.id) {
    const i = e.data,
      t = c(i),
      o = function (e) {
        const n = $;
        if (!e.uuid) return W.legacyMessageIdsToUuidMap[e.id];
        {
          const n = e.uuid,
            i = L(W.callbacks, n);
          if (i) return i;
          const t = L(W.promiseCallbacks, n);
          if (t) return t;
          const o = L(W.portCallbacks, n);
          if (o) return o;
        }
        n("Received message %s that failed to produce a callbackId", Z(e));
      }(t);
    if (o) {
      const i = W.callbacks.get(o);
      n("Received a response from parent for message %s", o.toString()), i && (n("Invoking the registered callback for message %s with arguments %o", o.toString(), t.args), i.apply(null, [...t.args, t.isPartialResponse]), function (e) {
        return !0 === e.data.isPartialResponse;
      }(e) || (n("Removing registered callback for message %s", o.toString()), V(t, W.callbacks)));
      const s = W.promiseCallbacks.get(o);
      s && (n("Invoking the registered promise callback for message %s with arguments %o", o.toString(), t.args), s(t.args), n("Removing registered promise callback for message %s", o.toString()), V(t, W.promiseCallbacks));
      const r = W.portCallbacks.get(o);
      if (r) {
        let i;
        n("Invoking the registered port callback for message %s with arguments %o", o.toString(), t.args), e.ports && e.ports[0] instanceof MessagePort && (i = e.ports[0]), r(i, t.args), n("Removing registered port callback for message %s", o.toString()), V(t, W.portCallbacks);
      }
      t.uuid && (W.legacyMessageIdsToUuidMap = {});
    }
  } else if ("func" in e.data && "string" == typeof e.data.func) {
    const i = e.data;
    n('Received a message from parent %s, action: "%s"', Z(i), i.func), a(i.func, i.args);
  } else n("Received an unknown message: %O", e);
}
const z = m.extend("handleIncomingMessageFromChild");
function q() {
  return h.topWindow !== h.parentWindow;
}
function D(e) {
  return e === h.topWindow && q() ? W.topMessageQueue : e === h.parentWindow ? W.parentMessageQueue : e === h.childWindow ? W.childMessageQueue : [];
}
function H(e) {
  return e === h.topWindow && q() ? h.topOrigin : e === h.parentWindow ? h.parentOrigin : e === h.childWindow ? h.childOrigin : null;
}
function J(e) {
  return e === h.topWindow && q() ? "top" : e === h.parentWindow ? "parent" : e === h.childWindow ? "child" : null;
}
const B = m.extend("flushMessageQueue");
function K(e) {
  const n = H(e),
    i = D(e),
    t = J(e);
  for (; e && n && i.length > 0;) {
    const o = i.shift();
    if (o) {
      const i = d(o);
      B("Flushing message %s from %s message queue via postMessage.", Z(i), t), e.postMessage(i, n);
    }
  }
}
function G(e, n) {
  let i;
  i = h.currentWindow.setInterval(() => {
    0 === D(e).length && (clearInterval(i), n());
  }, 100);
}
function X(e, n, i, t) {
  const o = h.childWindow,
    s = function (e, n, i, t) {
      return {
        id: e,
        uuid: n,
        args: i || [],
        isPartialResponse: t
      };
    }(e, n, i, t),
    r = l(s),
    a = H(o);
  o && a && o.postMessage(r, a);
}
function Y(e, n) {
  const i = h.childWindow,
    t = function (e, n) {
      return {
        func: e,
        args: n || []
      };
    }(e, n),
    o = H(i);
  i && o ? i.postMessage(t, o) : D(i).push(t);
}
function Z(e) {
  return "uuidAsString" in e ? `${e.uuidAsString} (legacy id: ${e.id})` : "uuid" in e && void 0 !== e.uuid ? `${e.uuid.toString()} (legacy id: ${e.id})` : `legacy id: ${e.id} (no uuid)`;
}
export { h as Communication, v as initializeCommunication, O as requestPortFromParentWithVersion, I as sendAndHandleSdkError, y as sendAndHandleStatusAndReason, k as sendAndHandleStatusAndReasonWithDefaultError, b as sendAndUnwrap, Y as sendMessageEventToChild, S as sendMessageToParent, T as sendMessageToParentAsync, R as sendNestedAuthRequestToTopWindow, M as uninitializeCommunication, G as waitForMessageQueue };