import { __awaiter as e } from "../../../../node_modules/.pnpm/@rollup_plugin-typescript@11.1.6_rollup@4.24.0_tslib@2.6.3_typescript@4.9.5/node_modules/tslib/tslib.es6.js";
import { getLogger as n, getApiVersionTag as r } from "./telemetry.js";
import { pages as l } from "../public/pages.js";
import { runtime as a } from "../public/runtime.js";
import { sendMessageToParent as d, Communication as o, sendMessageEventToChild as i } from "./communication.js";
import { ensureInitialized as t } from "./internalAPIs.js";
import { isNullOrUndefined as s } from "./typeCheckUtilities.js";
const u = n("handlers");
class f {
  static initializeHandlers() {
    f.handlers.themeChange = y, f.handlers.load = w, f.handlers.beforeUnload = O, l.backStack._initialize();
  }
  static uninitializeHandlers() {
    f.handlers = {}, f.themeChangeHandler = null, f.loadHandler = null, f.beforeUnloadHandler = null, f.beforeSuspendOrTerminateHandler = null, f.resumeHandler = null;
  }
}
function m() {
  f.initializeHandlers();
}
function c() {
  f.uninitializeHandlers();
}
f.handlers = {}, f.themeChangeHandler = null, f.loadHandler = null, f.beforeUnloadHandler = null, f.beforeSuspendOrTerminateHandler = null, f.resumeHandler = null;
const h = u.extend("callHandler");
function H(e, n) {
  const r = f.handlers[e];
  if (r) {
    h("Invoking the registered handler for message %s with arguments %o", e, n);
    return [!0, r.apply(this, n)];
  }
  return o.childWindow ? (i(e, n), [!1, void 0]) : (h("Handler for action message %s not found.", e), [!1, void 0]);
}
function p(e, n, r, l = !0, a = []) {
  r ? (f.handlers[n] = r, l && d(e, "registerHandler", [n, ...a])) : delete f.handlers[n];
}
function g(e) {
  delete f.handlers[e];
}
function b(e) {
  return null != f.handlers[e];
}
function U(e, n, r, l, d) {
  r && t(a, ...l), d && d(), p(e, n, r);
}
function v(e, n) {
  f.themeChangeHandler = n, !s(n) && d(e, "registerHandler", ["themeChange"]);
}
function y(e) {
  f.themeChangeHandler && f.themeChangeHandler(e), o.childWindow && i("themeChange", [e]);
}
function C(e, n) {
  f.loadHandler = n, !s(n) && d(e, "registerHandler", ["load"]);
}
function w(e) {
  const n = {
    entityId: (r = e).entityId,
    contentUrl: new URL(r.contentUrl)
  };
  var r;
  f.resumeHandler ? (f.resumeHandler(n), o.childWindow && i("load", [n])) : f.loadHandler && (f.loadHandler(e), o.childWindow && i("load", [e]));
}
function j(e, n) {
  f.beforeUnloadHandler = n, !s(n) && d(e, "registerHandler", ["beforeUnload"]);
}
function O() {
  return e(this, void 0, void 0, function* () {
    const e = () => {
      d(r("v2", "handleBeforeUnload"), "readyToUnload", []);
    };
    f.beforeSuspendOrTerminateHandler ? (yield f.beforeSuspendOrTerminateHandler(), o.childWindow ? i("beforeUnload") : e()) : f.beforeUnloadHandler && f.beforeUnloadHandler(e) || (o.childWindow ? i("beforeUnload") : e());
  });
}
function S(e) {
  f.beforeSuspendOrTerminateHandler = e, !s(e) && d(r("v2", "registerBeforeSuspendOrTerminateHandler"), "registerHandler", ["beforeUnload"]);
}
function T(e) {
  f.resumeHandler = e, !s(e) && d(r("v2", "registerOnResumeHandler"), "registerHandler", ["load"]);
}
export { H as callHandler, b as doesHandlerExist, y as handleThemeChange, m as initializeHandlers, S as registerBeforeSuspendOrTerminateHandler, j as registerBeforeUnloadHandler, p as registerHandler, U as registerHandlerHelper, C as registerOnLoadHandler, T as registerOnResumeHandler, v as registerOnThemeChangeHandler, g as removeHandler, c as uninitializeHandlers };