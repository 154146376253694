{
  "name": "directory",
  "version": "1.1.11",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "prebuild": "npm --no-git-tag-version version patch",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "e2e": "npx playwright test --project=chromium --headed",
    "lint": "ng lint",
    "deploy": "git checkout test && git merge master && git push && git checkout master",
    "upgrade": "npm-check --update",
    "prepare": "cd ../.. && husky TeamDirectory/directory"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.11",
    "@angular/cdk": "^18.2.12",
    "@angular/common": "^18.2.11",
    "@angular/compiler": "^18.2.11",
    "@angular/core": "^18.2.11",
    "@angular/forms": "^18.2.11",
    "@angular/material": "^18.2.12",
    "@angular/platform-browser": "^18.2.11",
    "@angular/platform-browser-dynamic": "^18.2.11",
    "@angular/router": "^18.2.11",
    "@angular/service-worker": "^18.2.11",
    "@fortawesome/angular-fontawesome": "^0.15.0",
    "@fortawesome/fontawesome-svg-core": "^6.6.0",
    "@fortawesome/free-solid-svg-icons": "^6.6.0",
    "@fullcalendar/angular": "^6.1.15",
    "@fullcalendar/core": "^6.1.15",
    "@fullcalendar/daygrid": "^6.1.15",
    "@ngrx/effects": "^18.1.1",
    "@ngrx/operators": "^18.1.1",
    "@ngrx/router-store": "^18.1.1",
    "@ngrx/store": "^18.1.1",
    "@ngrx/store-devtools": "^18.1.1",
    "ngx-markdown": "^18.1.0",
    "rxjs": "^7.5.7",
    "sharp": "^0.33.5",
    "tslib": "^2.6.3",
    "zone.js": "^0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.11",
    "@angular-eslint/builder": "^18.4.0",
    "@angular-eslint/eslint-plugin": "^18.4.0",
    "@angular-eslint/eslint-plugin-template": "^18.4.0",
    "@angular-eslint/schematics": "^18.4.0",
    "@angular-eslint/template-parser": "^18.4.0",
    "@angular/cli": "^18.2.11",
    "@angular/compiler-cli": "^18.2.11",
    "@azure/msal-angular": "^3.1.0",
    "@azure/msal-browser": "^3.27.0",
    "@fortawesome/free-brands-svg-icons": "^6.6.0",
    "@microsoft/applicationinsights-angularplugin-js": "^15.3.4",
    "@microsoft/applicationinsights-clickanalytics-js": "^3.3.4",
    "@microsoft/applicationinsights-web": "^3.3.4",
    "@microsoft/signalr": "^8.0.7",
    "@microsoft/teams-js": "^2.30.0",
    "@ngrx/schematics": "^18.1.1",
    "@playwright/test": "^1.48.2",
    "@rrweb/types": "^2.0.0-alpha.17",
    "@types/file-saver": "^2.0.7",
    "@types/jasmine": "^5.1.4",
    "@types/marked": "^6.0.0",
    "@types/node": "^22.2.0",
    "@typescript-eslint/eslint-plugin": "^8.13.0",
    "@typescript-eslint/parser": "^8.13.0",
    "angular-eslint": "^18.4.0",
    "bootstrap": "^5.3.3",
    "bootstrap-icons": "^1.11.3",
    "chart.js": "^4.4.6",
    "dayjs": "^1.11.13",
    "dexie": "^4.0.9",
    "dotenv": "^16.4.5",
    "eslint": "^9.14.0",
    "file-saver": "^2.0.5",
    "husky": "^9.1.6",
    "jasmine-core": "^5.4.0",
    "karma": "^6.4.4",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage": "^2.2.1",
    "karma-jasmine": "^5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "karma-spec-reporter": "^0.0.36",
    "ng2-charts": "^6.0.1",
    "ngrx-store-localstorage": "^18.0.0",
    "ngx-bootstrap": "^18.1.3",
    "ngx-logger": "^5.0.12",
    "ngx-toastr": "^19.0.0",
    "posthog-js": "^1.181.0",
    "rrweb-snapshot": "^2.0.0-alpha.17",
    "typescript": "^5.4.5",
    "typescript-eslint": "^8.13.0"
  },
  "overrides": {
    "ngrx-store-localstorage": {
      "@angular/common": "$@angular/common",
      "@angular/core": "$@angular/core",
      "@ngrx/store": "$@ngrx/store"
    }
  },
  "lint-staged": {
    "*.js": "eslint --cache --fix"
  }
}
