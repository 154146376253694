import { Buffer as t } from "../../../../_virtual/_polyfill-node.buffer.js";
import { minAdaptiveCardVersion as e } from "../public/constants.js";
import n from "../../../../node_modules/.pnpm/uuid@9.0.1/node_modules/uuid/dist/esm-browser/v4.js";
import r from "../../../../node_modules/.pnpm/uuid@9.0.1/node_modules/uuid/dist/esm-browser/validate.js";
function o(t) {
  return (t, e) => {
    if (!t) throw new Error(e);
  };
}
function i(t, e) {
  if ("string" != typeof t || "string" != typeof e) return NaN;
  const n = t.split("."),
    r = e.split(".");
  function o(t) {
    return /^\d+$/.test(t);
  }
  if (!n.every(o) || !r.every(o)) return NaN;
  for (; n.length < r.length;) n.push("0");
  for (; r.length < n.length;) r.push("0");
  for (let t = 0; t < n.length; ++t) if (Number(n[t]) != Number(r[t])) return Number(n[t]) > Number(r[t]) ? 1 : -1;
  return 0;
}
function u() {
  return n();
}
function c(t) {
  return Object.keys(t).forEach(e => {
    null !== t[e] && void 0 !== t[e] && "object" == typeof t[e] && c(t[e]);
  }), Object.freeze(t);
}
function s(t, e, ...n) {
  const r = t(...n);
  return r.then(t => {
    e && e(void 0, t);
  }).catch(t => {
    e && e(t);
  }), r;
}
function l(t, e, ...n) {
  const r = t(...n);
  return r.then(() => {
    e && e(null);
  }).catch(t => {
    e && e(t);
  }), r;
}
function a(t, e, ...n) {
  const r = t(...n);
  return r.then(t => {
    e && e(null, t);
  }).catch(t => {
    e && e(t, null);
  }), r;
}
function f(t, e, n) {
  return new Promise((r, o) => {
    const i = setTimeout(o, e, n);
    t().then(t => {
      clearTimeout(i), r(t);
    }).catch(t => {
      clearTimeout(i), o(t);
    });
  });
}
function d(t) {
  const e = new URL("https://teams.microsoft.com/l/entity/" + encodeURIComponent(t.appId.toString()) + "/" + encodeURIComponent(t.pageId));
  return t.webUrl && e.searchParams.append("webUrl", t.webUrl.toString()), (t.chatId || t.channelId || t.subPageId) && e.searchParams.append("context", JSON.stringify({
    chatId: t.chatId,
    channelId: t.channelId,
    subEntityId: t.subPageId
  })), e.toString();
}
function h(t) {
  return !(i(`${t.majorVersion}.${t.minorVersion}`, `${e.majorVersion}.${e.minorVersion}`) >= 0);
}
function m(t) {
  return "https:" === t.protocol;
}
function p(e, n) {
  return new Promise((r, o) => {
    if (e || o("MimeType cannot be null or empty."), n || o("Base64 string cannot be null or empty."), e.startsWith("image/")) {
      const t = atob(n),
        o = new Uint8Array(t.length);
      for (let e = 0; e < t.length; e++) o[e] = t.charCodeAt(e);
      r(new Blob([o], {
        type: e
      }));
    }
    const i = t.from(n, "base64").toString();
    r(new Blob([i], {
      type: e
    }));
  });
}
function w(t) {
  return new Promise((e, n) => {
    0 === t.size && n(new Error("Blob cannot be empty."));
    const r = new FileReader();
    r.onloadend = () => {
      r.result ? e(r.result.toString().split(",")[1]) : n(new Error("Failed to read the blob"));
    }, r.onerror = () => {
      n(r.error);
    }, r.readAsDataURL(t);
  });
}
function b() {
  if (g()) throw new Error("window object undefined at SSR check");
  return window;
}
function g() {
  return "undefined" == typeof window;
}
function y(t, e) {
  if (U(t) || !function (t) {
    return t.length < 256 && t.length > 4;
  }(t) || !function (t) {
    for (let e = 0; e < t.length; e++) {
      const n = t.charCodeAt(e);
      if (n < 32 || n > 126) return !1;
    }
    return !0;
  }(t)) throw e || new Error("id is not valid.");
}
function E(t, e) {
  const n = t.toString().toLocaleLowerCase();
  if (U(n)) throw new Error("Invalid Url");
  if (n.length > 2048) throw new Error("Url exceeds the maximum size of 2048 characters");
  if (!m(t)) throw new Error("Url should be a valid https url");
}
function I(t) {
  const e = document.createElement("a");
  return e.href = t, new URL(e.href);
}
function U(t) {
  return new RegExp(`${/<script[^>]*>|&lt;script[^&]*&gt;|%3Cscript[^%]*%3E/gi.source}|${/<\/script[^>]*>|&lt;\/script[^&]*&gt;|%3C\/script[^%]*%3E/gi.source}`, "gi").test(t);
}
function v(t) {
  if (!t) throw new Error("id must not be empty");
  if (!1 === r(t)) throw new Error("id must be a valid UUID");
}
export { p as base64ToBlob, s as callCallbackWithErrorOrResultFromPromiseAndReturnPromise, a as callCallbackWithErrorOrResultOrNullFromPromiseAndReturnPromise, l as callCallbackWithSdkErrorFromPromiseAndReturnPromise, i as compareSDKVersions, d as createTeamsAppLink, c as deepFreeze, I as fullyQualifyUrlString, u as generateGUID, w as getBase64StringFromBlob, o as getGenericOnCompleteHandler, U as hasScriptTags, g as inServerSideRenderingEnvironment, h as isHostAdaptiveCardSchemaVersionUnsupported, m as isValidHttpsURL, f as runWithTimeout, b as ssrSafeWindow, y as validateId, E as validateUrl, v as validateUuid };