import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BrowserService implements OnDestroy {
    private unsubscribe$ = new Subject<void>();
    public screenWidth$ = new BehaviorSubject<number>(null);
    public mediaBreakpoint$ = new BehaviorSubject<string>(null);
    public isOnline$ = new BehaviorSubject<boolean>(true);
    public isIframe = window !== window.parent && !window.opener;

    isOnline = true;
    constructor() {
        this.init();
    }

    init() {
        this._setScreenWidth(window.innerWidth);
        this._setMediaBreakpoint(window.innerWidth);

        fromEvent(window, 'resize')
            .pipe(debounceTime(500), takeUntil(this.unsubscribe$))
            .subscribe((evt: UIEvent) => {
                this._setScreenWidth((evt.target as Window).innerWidth);
                this._setMediaBreakpoint((evt.target as Window).innerWidth);
            });

        fromEvent(window, 'online')
            .pipe(debounceTime(500), takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.isOnline = true;
                this.isOnline$.next(true);
            });

        fromEvent(window, 'offline')
            .pipe(debounceTime(500), takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.isOnline = false;
                this.isOnline$.next(false);
            });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private _setScreenWidth(width: number): void {
        this.screenWidth$.next(width);
    }

    private _setMediaBreakpoint(width: number): void {
        if (width < 576) {
            this.mediaBreakpoint$.next('xs');
        } else if (width >= 576 && width < 768) {
            this.mediaBreakpoint$.next('sm');
        } else if (width >= 768 && width < 992) {
            this.mediaBreakpoint$.next('md');
        } else if (width >= 992 && width < 1200) {
            this.mediaBreakpoint$.next('lg');
        } else if (width >= 1200 && width < 1600) {
            this.mediaBreakpoint$.next('xl');
        } else {
            this.mediaBreakpoint$.next('xxl');
        }
    }
}
