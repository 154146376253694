import { s as t } from "../../../../node_modules/.pnpm/debug@4.3.5/node_modules/debug/src/index.js";
import { UUID as e } from "./uuidObject.js";
const n = new e(),
  o = t.debug.formatArgs;
t.debug.formatArgs = function (t) {
  t[0] = `(${new Date().toISOString()}): ${t[0]} [${n.toString()}]`, o.call(this, t);
};
const r = t.debug("teamsJs");
function u(t) {
  return r.extend(t);
}
function s(t, e) {
  return `${t}_${e}`;
}
function d(t) {
  return /^v\d+_[\w.]+$/.test(t);
}
export { s as getApiVersionTag, u as getLogger, d as isFollowingApiVersionTagFormat, n as teamsJsInstanceIdentifier };