import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as dataActions from '../../data/data.actions';

@Component({
    selector: 'dir-help-button',
    templateUrl: './help-button.component.html',
    styleUrl: './help-button.component.scss'
})
export class HelpButtonComponent {
    @Input() topic = '';
    constructor(private store: Store) {}
    openHelp($event: MouseEvent) {
        if (this.topic !== '') {
            this.store.dispatch(dataActions.showHelpDialog(this.topic));
        }
        $event.preventDefault();
        return false;
    }
}
