import { sendMessageToParent as e } from "../internal/communication.js";
import { GlobalVars as t } from "../internal/globalVars.js";
import { registerHandlerHelper as n } from "../internal/handlers.js";
import { ensureInitializeCalled as r, ensureInitialized as o } from "../internal/internalAPIs.js";
import { getApiVersionTag as i } from "../internal/telemetry.js";
import { getGenericOnCompleteHandler as a } from "../internal/utils.js";
import { appInitializeHelper as s, registerOnThemeChangeHandlerHelper as l, openLinkHelper as c } from "./app.js";
import { FrameContexts as u } from "./constants.js";
import { pages as f, getTabInstancesHelper as m, getMruTabInstancesHelper as p, shareDeepLinkHelper as g, setCurrentFrameHelper as d } from "./pages.js";
import { runtime as H } from "./runtime.js";
import { teamsCore as b } from "./teamsAPIs.js";
const h = "v1";
function C(e, t) {
  s(i(h, "initialize"), t).then(() => {
    e && e();
  });
}
function B() {
  b.enablePrintCapability();
}
function j() {
  b.print();
}
function x(n) {
  r(), e(i(h, "getContext"), "getContext", e => {
    e.frameContext || (e.frameContext = t.frameContext), n(e);
  });
}
function k(e) {
  l(i(h, "registerOnThemeChangeHandlerHelper"), e);
}
function L(e) {
  n(i(h, "registerFullScreenHandler"), "fullScreenChange", e, []);
}
function v(e) {
  n(i(h, "registerAppButtonClickHandler"), "appButtonClick", e, [u.content]);
}
function E(e) {
  n(i(h, "registerAppButtonHoverEnterHandler"), "appButtonHoverEnter", e, [u.content]);
}
function P(e) {
  n(i(h, "registerAppButtonHoverLeaveHandler"), "appButtonHoverLeave", e, [u.content]);
}
function I(e) {
  f.backStack.registerBackButtonHandlerHelper(i(h, "registerBackButtonHandler"), e);
}
function S(e) {
  b.registerOnLoadHandlerHelper(i(h, "registerOnLoadHandler"), e);
}
function y(e) {
  b.registerBeforeUnloadHandlerHelper(i(h, "registerBeforeUnloadHandler"), e);
}
function A(e) {
  n(i(h, "registerFocusEnterHandler"), "focusEnter", e, []);
}
function F(e) {
  n(i(h, "registerChangeSettingsHandler"), "changeSettings", e, [u.content]);
}
function U(e, t) {
  o(H), m(i(h, "getTabInstances"), t).then(t => {
    e(t);
  });
}
function O(e, t) {
  o(H), p(i(h, "getMruTabInstances"), t).then(t => {
    e(t);
  });
}
function T(e) {
  g(i(h, "shareDeepLink"), {
    subPageId: e.subEntityId,
    subPageLabel: e.subEntityLabel,
    subPageWebUrl: e.subEntityWebUrl
  });
}
function W(e, t) {
  o(H, u.content, u.sidePanel, u.settings, u.task, u.stage, u.meetingStage);
  const n = null != t ? t : a();
  c(i(h, "executeDeepLink"), e).then(() => {
    n(!0);
  }).catch(e => {
    n(!1, e.message);
  });
}
function z(e) {
  d(i(h, "setFrameContext"), e);
}
function D(e, t, n) {
  s(i(h, "initializeWithFrameContext"), n).then(() => t && t()), d(i(h, "setFrameContext"), e);
}
export { B as enablePrintCapability, W as executeDeepLink, x as getContext, O as getMruTabInstances, U as getTabInstances, C as initialize, D as initializeWithFrameContext, j as print, v as registerAppButtonClickHandler, E as registerAppButtonHoverEnterHandler, P as registerAppButtonHoverLeaveHandler, I as registerBackButtonHandler, y as registerBeforeUnloadHandler, F as registerChangeSettingsHandler, A as registerFocusEnterHandler, L as registerFullScreenHandler, S as registerOnLoadHandler, k as registerOnThemeChangeHandler, z as setFrameContext, T as shareDeepLink };