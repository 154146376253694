import e from "./_polyfill-node.global.js";
function t() {
  throw new Error("setTimeout has not been defined");
}
function r() {
  throw new Error("clearTimeout has not been defined");
}
var n = t,
  o = r;
function i(e) {
  if (n === setTimeout) return setTimeout(e, 0);
  if ((n === t || !n) && setTimeout) return n = setTimeout, setTimeout(e, 0);
  try {
    return n(e, 0);
  } catch (t) {
    try {
      return n.call(null, e, 0);
    } catch (t) {
      return n.call(this, e, 0);
    }
  }
}
"function" == typeof e.setTimeout && (n = setTimeout), "function" == typeof e.clearTimeout && (o = clearTimeout);
var u,
  a = [],
  c = !1,
  l = -1;
function f() {
  c && u && (c = !1, u.length ? a = u.concat(a) : l = -1, a.length && s());
}
function s() {
  if (!c) {
    var e = i(f);
    c = !0;
    for (var t = a.length; t;) {
      for (u = a, a = []; ++l < t;) u && u[l].run();
      l = -1, t = a.length;
    }
    u = null, c = !1, function (e) {
      if (o === clearTimeout) return clearTimeout(e);
      if ((o === r || !o) && clearTimeout) return o = clearTimeout, clearTimeout(e);
      try {
        return o(e);
      } catch (t) {
        try {
          return o.call(null, e);
        } catch (t) {
          return o.call(this, e);
        }
      }
    }(e);
  }
}
function h(e) {
  var t = new Array(arguments.length - 1);
  if (arguments.length > 1) for (var r = 1; r < arguments.length; r++) t[r - 1] = arguments[r];
  a.push(new m(e, t)), 1 !== a.length || c || i(s);
}
function m(e, t) {
  this.fun = e, this.array = t;
}
m.prototype.run = function () {
  this.fun.apply(null, this.array);
};
var w = "browser",
  p = "browser",
  v = !0,
  T = {},
  d = [],
  g = "",
  y = {},
  b = {},
  E = {};
function N() {}
var k = N,
  D = N,
  L = N,
  x = N,
  A = N,
  M = N,
  j = N;
function z(e) {
  throw new Error("process.binding is not supported");
}
function _() {
  return "/";
}
function q(e) {
  throw new Error("process.chdir is not supported");
}
function B() {
  return 0;
}
var C = e.performance || {},
  F = C.now || C.mozNow || C.msNow || C.oNow || C.webkitNow || function () {
    return new Date().getTime();
  };
function G(e) {
  var t = .001 * F.call(C),
    r = Math.floor(t),
    n = Math.floor(t % 1 * 1e9);
  return e && (r -= e[0], (n -= e[1]) < 0 && (r--, n += 1e9)), [r, n];
}
var H = new Date();
function I() {
  return (new Date() - H) / 1e3;
}
var J = {
  nextTick: h,
  title: w,
  browser: v,
  env: T,
  argv: d,
  version: "",
  versions: y,
  on: k,
  addListener: D,
  once: L,
  off: x,
  removeListener: A,
  removeAllListeners: M,
  emit: j,
  binding: z,
  cwd: _,
  chdir: q,
  umask: B,
  hrtime: G,
  platform: p,
  release: b,
  config: E,
  uptime: I
};
export { D as addListener, d as argv, z as binding, v as browser, q as chdir, E as config, _ as cwd, J as default, j as emit, T as env, G as hrtime, h as nextTick, x as off, k as on, L as once, p as platform, b as release, M as removeAllListeners, A as removeListener, w as title, B as umask, I as uptime, g as version, y as versions };