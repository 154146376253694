var t;
t = "function" == typeof Object.create ? function (t, e) {
  t.super_ = e, t.prototype = Object.create(e.prototype, {
    constructor: {
      value: t,
      enumerable: !1,
      writable: !0,
      configurable: !0
    }
  });
} : function (t, e) {
  t.super_ = e;
  var o = function () {};
  o.prototype = e.prototype, t.prototype = new o(), t.prototype.constructor = t;
};
export { t as default };