import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AsideComponent } from './components/aside.component';
import { RouterModule } from '@angular/router';
import { CategoryMenuItemComponent } from './components/categories/category-menu-item.component';
import { DataSourceWidgetComponent } from './components/data-source-widget.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FooterComponent } from './components/footer.component';
import { HeaderComponent } from './components/header.component';
import { TopbarComponent } from './components/topbar.component';
import { MatMenuModule } from '@angular/material/menu';
import { GraphPictureComponent } from './components/graph-picture.component';
import { SearchFormComponent } from './components/search-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HelpButtonComponent } from './components/help-button.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FeatureConsentComponent } from './components/features/feature-consent.component';
import { ImageFallbackDirective } from './directives/image-fallback.directive';
import { DropZoneDirective } from './directives/drop-zone.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { AdminButtonComponent } from './components/admin-button.component';
import { FeatureConsentButtonComponent } from './components/features/feature-consent-button.component';
import { UserAvailabilityStripComponent } from '../userAvailability/components/user-availability-strip.component';
import { UserPresenceComponent } from '../userAvailability/components/user-presence.component';
import { PresenceStatusPipe } from '../userAvailability/pipes/presence-status.pipe';
import { HighlightSearchPipe } from './pipes/highlight-search.pipe';
import { CategoryItemFieldPipe } from './pipes/category-item-field.pipe';
import { ObscureTextPipe } from './pipes/obscure-text.pipe';

@NgModule({
    declarations: [
        AsideComponent,
        CategoryMenuItemComponent,
        DataSourceWidgetComponent,
        FooterComponent,
        HeaderComponent,
        TopbarComponent,
        GraphPictureComponent,
        SearchFormComponent,
        HelpButtonComponent,
        FeatureConsentComponent,
        ImageFallbackDirective,
        DropZoneDirective,
        AdminButtonComponent,
        FeatureConsentButtonComponent,
        PresenceStatusPipe,
        UserPresenceComponent,
        UserAvailabilityStripComponent,
        HighlightSearchPipe,
        CategoryItemFieldPipe,
        ObscureTextPipe
    ],
    exports: [
        AsideComponent,
        CategoryMenuItemComponent,
        DataSourceWidgetComponent,
        FooterComponent,
        HeaderComponent,
        HighlightSearchPipe,
        CategoryItemFieldPipe,
        ObscureTextPipe,
        TopbarComponent,
        GraphPictureComponent,
        SearchFormComponent,
        HelpButtonComponent,
        FeatureConsentComponent,
        ImageFallbackDirective,
        DropZoneDirective,
        AdminButtonComponent,
        FeatureConsentButtonComponent,
        PresenceStatusPipe,
        UserPresenceComponent,
        UserAvailabilityStripComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TypeaheadModule.forRoot(),
        TooltipModule.forRoot(),
        MatMenuModule,
        MatFormFieldModule, //
        MatIconModule, //
        MatDialogModule //
    ]
    //providers: [FeatureService]
})
export class SharedModule {}
