import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { Store } from '@ngrx/store';
import { AzureDataService } from './azure.service';
import { AppDB } from './db.service';
import { DemoDataService } from './demo-data.service';
import { DataSourceHelper, DefaultAzureSourceChartId } from '../utils/data-source-helper';
import { ChartService } from './chart.service';
import { TocService } from './toc.service';
import { AzureDeltaService } from './azure-delta.service';
import { FeatureService } from '../shared/services/feature.service';

export const DataServiceFactoryDeprecated = (
    http: HttpClient,
    store: Store,
    db: AppDB,
    featureService: FeatureService,
    authService: MsalService,
    tocService: TocService
) => {
    //check local storage for demo flag
    //if demo flag is set, return demo data service
    //else return azure data service
    const isDemo = localStorage.getItem('isDemo');
    if (isDemo === 'true') {
        return new DemoDataService(store, db);
    }
    const dataSource = DataSourceHelper.GetSelectedDataSourceChartId();
    if (dataSource && dataSource !== DefaultAzureSourceChartId) {
        return new ChartService(store, db, tocService);
    }
    return new AzureDeltaService(http, store, db, featureService); ////authService,);
};

export enum DataSourceType {
    Demo = 1,
    Graph = 2,
    Chart = 3
}
