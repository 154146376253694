import { sendAndHandleStatusAndReason as o } from "../internal/communication.js";
import { ensureInitialized as r } from "../internal/internalAPIs.js";
import { getApiVersionTag as e } from "../internal/telemetry.js";
import { FrameContexts as t } from "./constants.js";
import { runtime as i } from "./runtime.js";
var n;
!function (n) {
  function m() {
    return !(!r(i) || !i.supports.mail);
  }
  var p;
  n.openMailItem = function (n) {
    return new Promise(p => {
      if (r(i, t.content), !m()) throw new Error("Not supported");
      if (!n.itemId || !n.itemId.trim()) throw new Error("Must supply an itemId to openMailItem");
      p(o(e("v2", "mail.openMailItem"), "mail.openMailItem", n));
    });
  }, n.composeMail = function (n) {
    return new Promise(p => {
      if (r(i, t.content), !m()) throw new Error("Not supported");
      p(o(e("v2", "mail.composeMail"), "mail.composeMail", n));
    });
  }, n.isSupported = m, (p = n.ComposeMailType || (n.ComposeMailType = {})).New = "new", p.Reply = "reply", p.ReplyAll = "replyAll", p.Forward = "forward";
}(n || (n = {}));
export { n as mail };