import { sendAndHandleStatusAndReason as e } from "../internal/communication.js";
import { createTeamsDeepLinkForChat as t } from "../internal/deepLinkUtilities.js";
import { ensureInitialized as s } from "../internal/internalAPIs.js";
import { getApiVersionTag as r } from "../internal/telemetry.js";
import { FrameContexts as n, errorNotSupportedOnPlatform as o } from "./constants.js";
import { runtime as i } from "./runtime.js";
var a;
!function (a) {
  function m(r, a) {
    return new Promise(m => {
      if (s(i, n.content, n.task), !p()) throw o;
      if (i.isLegacyTeams) m(e(r, "executeDeepLink", t([a.user], void 0, a.message)));else {
        m(e(r, "chat.openChat", {
          members: [a.user],
          message: a.message
        }));
      }
    });
  }
  function p() {
    return !(!s(i) || !i.supports.chat);
  }
  a.openChat = function (e) {
    return m(r("v2", "chat.openChat"), e);
  }, a.openGroupChat = function (a) {
    const u = r("v2", "chat.openGroupChat");
    return new Promise(r => {
      if (a.users.length < 1) throw Error("OpenGroupChat Failed: No users specified");
      if (1 === a.users.length) {
        const e = {
          user: a.users[0],
          message: a.message
        };
        r(m(u, e));
      } else {
        if (s(i, n.content, n.task), !p()) throw o;
        if (i.isLegacyTeams) r(e(u, "executeDeepLink", t(a.users, a.topic, a.message)));else {
          r(e(u, "chat.openChat", {
            members: a.users,
            message: a.message,
            topic: a.topic
          }));
        }
      }
    });
  }, a.isSupported = p;
}(a || (a = {}));
export { a as chat };