import { Component, Input, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import * as fromRoot from '../../reducers';
import * as dataActions from '../../data/data.actions';
import * as uiActions from '../../ui/ui.actions';
import { uiFeature } from 'src/app/ui/ui.reducer';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Category } from '../../data/categories';
import { MediaMatcher } from '@angular/cdk/layout';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'dir-aside',
    templateUrl: './aside.component.html',
    styleUrls: ['./aside.component.scss'],
    animations: [
        trigger('slideUpDown', [
            state('0', style({ 'max-height': '*', opacity: 1 })),
            state('1', style({ 'max-height': '0px', opacity: 0 })),
            transition(':enter', animate('1800ms ease-in-out')),
            transition('* => *', animate('1800ms ease-in-out')),
            transition('* => void', animate('1400ms ease-in-out'))
        ]),
        trigger('slideDownUp', [
            transition(':enter', [style({ height: 0 }), animate('300ms ease-out')]),
            transition(':leave', [animate('300ms ease-out', style({ height: 0 }))])
        ])
    ]
})
export class AsideComponent implements OnInit, OnDestroy {
    @Input() shiftDownPx = 0;
    private readonly unsubscribe$ = new Subject<void>();
    expandedCategoriesIds$: Observable<number[]>;
    categories$: Observable<Category[]>;
    isDrawerOpen$: Observable<boolean>;
    expandedCategoriesIds: number[] = [];
    isAdmin$ = this.store.select(fromRoot.selectUserIsAdmin);
    isEntra$ = this.store.select(fromRoot.selectIsEntraSource);
    isDrawerSize = false;
    envName = environment.environmentName;
    favorites$ = this.store.pipe(select(fromRoot.selectFavorites));
    hostedInTOC = false;
    theme$ = this.store.pipe(select(uiFeature.selectTheme));
    theme = 'light';
    highlightIntegrity = true;

    private mobileQuery: MediaQueryList;
    private _mobileQueryListener: (event: MediaQueryListEvent) => void;
    constructor(private store: Store, private changeDetectorRef: ChangeDetectorRef, private media: MediaMatcher) {
        this.mobileQuery = media.matchMedia('(max-width: 992px)'); //992px = lg, 768px = md
        this._mobileQueryListener = (event: MediaQueryListEvent) => {
            changeDetectorRef.detectChanges();
            console.log(event);
            this.isDrawerSize = event.matches;
        };
        this.mobileQuery.addEventListener('change', this._mobileQueryListener);
        this.isDrawerSize = this.mobileQuery.matches;

        this.store.pipe(select(uiFeature.selectTheme), takeUntil(this.unsubscribe$)).subscribe((theme) => {
            //handle auto theme
            if (theme === 'auto') {
                //get user's preferred theme
                theme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
            }
            this.theme = theme;
        });
    }

    ngOnInit(): void {
        this.expandedCategoriesIds$ = this.store.pipe(select(fromRoot.selectExpandedCategoriesIds));
        this.categories$ = this.store.pipe(select(fromRoot.selectCategories));
        this.expandedCategoriesIds$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((ids) => (this.expandedCategoriesIds = ids));

        this.store
            .select(uiFeature.selectHostedInTOC)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((hostedInTOC) => {
                this.hostedInTOC = hostedInTOC;
            });

        this.isDrawerOpen$ = this.store.pipe(select(uiFeature.selectIsDrawerOpen));

        this.highlightIntegrity = localStorage.getItem('visitedIntegrity') !== 'true';
    }

    onToggleExpandCategory(id: number) {
        this.store.dispatch(dataActions.toggleExpandCategory({ id }));
    }

    isExpanded(category: Category) {
        return this.expandedCategoriesIds.includes(category.id);
    }

    closeDrawer() {
        this.store.dispatch(uiActions.drawerClose());
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
    }
}
