import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TocChartItem, TocChartsResponse } from '../settings/settings.models';

@Injectable({
    providedIn: 'root'
})
export class TocService {
    constructor(private http: HttpClient) {}

    fetchCharts() {
        console.log('-=-=-=-=fetchCharts-=-=-=-=ssss');
        return this.http.get<TocChartsResponse>(environment.tocUrl + '/api/td/charts');
    }

    fetchChartData(chartId: string) {
        return this.http.get<TocChartItem[]>(environment.tocUrl + `/api/td/data/${chartId}`);
    }

    checkTenantExists(tenantId: string): Observable<boolean> {
        return this.http.get(environment.tocUrl + `/api/td/exists/${tenantId}`).pipe(
            map(() => true),
            catchError((error) => {
                if (error.status === 404) {
                    return of(false);
                }
            })
        );
    }
}
