var n, e, t, E, O, R, o, T, N, _;
function i(n) {
  return void 0 !== (null == n ? void 0 : n.errorCode);
}
!function (n) {
  n.Inline = "inline", n.Desktop = "desktop", n.Web = "web";
}(n || (n = {})), function (n) {
  n.M365Content = "m365content";
}(e || (e = {})), function (n) {
  n.DriveId = "driveId", n.GroupId = "groupId", n.SiteId = "siteId", n.UserId = "userId";
}(t || (t = {})), function (n) {
  n[n.NOT_SUPPORTED_ON_PLATFORM = 100] = "NOT_SUPPORTED_ON_PLATFORM", n[n.INTERNAL_ERROR = 500] = "INTERNAL_ERROR", n[n.NOT_SUPPORTED_IN_CURRENT_CONTEXT = 501] = "NOT_SUPPORTED_IN_CURRENT_CONTEXT", n[n.PERMISSION_DENIED = 1e3] = "PERMISSION_DENIED", n[n.NETWORK_ERROR = 2e3] = "NETWORK_ERROR", n[n.NO_HW_SUPPORT = 3e3] = "NO_HW_SUPPORT", n[n.INVALID_ARGUMENTS = 4e3] = "INVALID_ARGUMENTS", n[n.UNAUTHORIZED_USER_OPERATION = 5e3] = "UNAUTHORIZED_USER_OPERATION", n[n.INSUFFICIENT_RESOURCES = 6e3] = "INSUFFICIENT_RESOURCES", n[n.THROTTLE = 7e3] = "THROTTLE", n[n.USER_ABORT = 8e3] = "USER_ABORT", n[n.OPERATION_TIMED_OUT = 8001] = "OPERATION_TIMED_OUT", n[n.OLD_PLATFORM = 9e3] = "OLD_PLATFORM", n[n.FILE_NOT_FOUND = 404] = "FILE_NOT_FOUND", n[n.SIZE_EXCEEDED = 1e4] = "SIZE_EXCEEDED";
}(E || (E = {})), function (n) {
  n.GeoLocation = "geolocation", n.Media = "media";
}(O || (O = {})), function (n) {
  n.BCAIS = "bcais", n.BCWAF = "bcwaf", n.BCWBF = "bcwbf";
}(R || (R = {})), function (n) {
  n.Faculty = "faculty", n.Student = "student", n.Other = "other";
}(o || (o = {})), function (n) {
  n.Adult = "adult", n.MinorNoParentalConsentRequired = "minorNoParentalConsentRequired", n.MinorWithoutParentalConsent = "minorWithoutParentalConsent", n.MinorWithParentalConsent = "minorWithParentalConsent", n.NonAdult = "nonAdult";
}(T || (T = {})), function (n) {
  n.HigherEducation = "higherEducation", n.K12 = "k12", n.Other = "other";
}(N || (N = {})), function (n) {
  n.TextPlain = "text/plain", n.TextHtml = "text/html", n.ImagePNG = "image/png", n.ImageJPEG = "image/jpeg";
}(_ || (_ = {}));
export { e as ActionObjectType, _ as ClipboardSupportedMimeType, R as Cohort, O as DevicePermission, N as EduType, E as ErrorCode, n as FileOpenPreference, T as LegalAgeGroupClassification, o as Persona, t as SecondaryM365ContentIdName, i as isSdkError };