const e = "/l/app/",
  t = "/l/meeting/new",
  s = "attendees",
  a = "startTime",
  c = "endTime",
  n = "subject",
  i = "content",
  l = "/l/call/0/0",
  o = "source",
  m = "withVideo",
  p = "/l/chat/0/0",
  r = "users",
  d = "topicName",
  u = "message";
export { s as teamsDeepLinkAttendeesUrlParameterName, i as teamsDeepLinkContentUrlParameterName, c as teamsDeepLinkEndTimeUrlParameterName, u as teamsDeepLinkMessageUrlParameterName, o as teamsDeepLinkSourceUrlParameterName, a as teamsDeepLinkStartTimeUrlParameterName, n as teamsDeepLinkSubjectUrlParameterName, d as teamsDeepLinkTopicUrlParameterName, e as teamsDeepLinkUrlPathForAppInstall, t as teamsDeepLinkUrlPathForCalendar, l as teamsDeepLinkUrlPathForCall, p as teamsDeepLinkUrlPathForChat, r as teamsDeepLinkUsersUrlParameterName, m as teamsDeepLinkWithVideoUrlParameterName };