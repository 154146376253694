import { HostClientType as i } from "../public/constants.js";
import { ErrorCode as t } from "../public/interfaces.js";
import { isRuntimeInitialized as e } from "../public/runtime.js";
import { errorLibraryNotInitialized as r, defaultSDKVersionForCompatCheck as n, userOriginUrlValidationRegExp as o } from "./constants.js";
import { GlobalVars as l } from "./globalVars.js";
import { getLogger as s } from "./telemetry.js";
import { compareSDKVersions as a } from "./utils.js";
const f = s("internal"),
  c = f.extend("ensureInitializeCalled"),
  d = f.extend("ensureInitialized");
function m() {
  if (!l.initializeCalled) throw c(r), new Error(r);
}
function p(i, ...t) {
  if (!l.initializeCompleted) throw d("%s. initializeCalled: %s", r, l.initializeCalled.toString()), new Error(r);
  if (t && t.length > 0) {
    let i = !1;
    for (let e = 0; e < t.length; e++) if (t[e] === l.frameContext) {
      i = !0;
      break;
    }
    if (!i) throw new Error(`This call is only allowed in following contexts: ${JSON.stringify(t)}. Current context: "${l.frameContext}".`);
  }
  return e(i);
}
function u(i = n) {
  const t = a(l.clientSupportedSDKVersion, i);
  return !isNaN(t) && t >= 0;
}
function C() {
  return l.hostClientType == i.android || l.hostClientType == i.ios || l.hostClientType == i.ipados;
}
function h(i = n) {
  if (!C()) {
    throw {
      errorCode: t.NOT_SUPPORTED_ON_PLATFORM
    };
  }
  if (!u(i)) {
    throw {
      errorCode: t.OLD_PLATFORM
    };
  }
}
function w(i) {
  let t = l.additionalValidOrigins.concat(i.filter(i => "string" == typeof i && o.test(i)));
  const e = {};
  t = t.filter(i => !e[i] && (e[i] = !0, !0)), l.additionalValidOrigins = t;
}
export { m as ensureInitializeCalled, p as ensureInitialized, u as isCurrentSDKVersionAtLeast, C as isHostClientMobile, w as processAdditionalValidOrigins, h as throwExceptionIfMobileApiIsNotSupported };