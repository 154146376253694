export class UserPresence {
    id: string;
    availability: string;
    activity: string;
    statusMessage: StatusMessage | null;
    time: Date;
    constructor(id: string, availability: string, activity: string, statusMessage: StatusMessage | null, time : Date) {
        this.id = id;
        this.availability = availability;
        this.activity = activity;
        this.statusMessage = statusMessage;
        this.time = time;
    }
}

export interface GraphUserPresence {
    id: string;
    availability: string;
    activity: string;
    statusMessage: StatusMessage | null;
}

export interface StatusMessage {
    publishedDateTime: string | null;
    expiryDateTime: string | null;
    message: {
        content: string;
        contentType: string;
    } | null;
}

interface TimeInfo {
    dateTime: string;
    timeZone: string;
}

export interface UserSchedulesRequest {
    schedules: string[];
    startTime: TimeInfo;
    endTime: TimeInfo;
    availabilityViewInterval: number;
}

export interface UserAvailability {
    Mail: string;
    Availability: string;
    NoCalendar: boolean;
    WorkingHours: WorkingHours;
}

export interface WorkingHours {
    StartTime: string
    EndTime: string
    TimeZone: string
}

export interface GraphWorkingHours {
    startTime: string;
    endTime: string;
    daysOfWeek: string[];
    timeZone: GraphTimeZone;
}

export interface GraphTimeZone {
    name: string;
}

export interface GraphMailboxSettings {
    archiveFolder: string;
    //automaticRepliesSetting: AutomaticRepliesSetting;
    dateFormat: string;
    workingHours: GraphWorkingHours;
}

export interface GraphScheduleInformation {
    availabilityView: string;
    scheduleId: string;
    workingHours: GraphWorkingHours;
}

