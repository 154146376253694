import { Store, select } from '@ngrx/store';
import { DataServiceResponse, IDataService } from './data.service';
import { AppDB } from './db.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
    EMPTY,
    Observable,
    catchError,
    concat,
    expand,
    forkJoin,
    from,
    map,
    mergeMap,
    of,
    switchMap,
    take,
    tap,
    toArray
} from 'rxjs';
import { Injectable } from '@angular/core';
import {
    DirectoryUser,
    MsGraphBatchUserDetails,
    MsGraphBatchUserResponse,
    MsGraphDeltaResponse,
    MsGraphUsersResponse,
    directoryFieldNames,
    directoryFieldNamesDelta,
    graphUserToDirectoryUser
} from '../data/data.models';
import * as dataActions from '../data/data.actions';
import { DataSourceType } from './data-service.factory';
import * as fromRoot from '../reducers';
import { settingsFeature } from '../settings/settings.reducer';
import { FeatureService } from '../shared/services/feature.service';
import { Feature } from '../shared/components/features/features.models';

const PAGE_SIZE = 200;
const USERS_COUNT = 'https://graph.microsoft.com/v1.0/users/$count';
const BATCH_REQUEST = 'https://graph.microsoft.com/v1.0/$batch';
const USERS_ENDPOINT_FULL = `https://graph.microsoft.com/v1.0/users?$top=${PAGE_SIZE}&$select=${directoryFieldNames()},onPremisesExtensionAttributes&$expand=manager($select=id,displayName)`; //
const USERS_ENDPOINT_PARTIAL = `https://graph.microsoft.com/v1.0/users?$top=${PAGE_SIZE}&$select=${directoryFieldNames()},onPremisesExtensionAttributes`; //
const USERS_LATEST_DELTA = 'https://graph.microsoft.com/v1.0/users/delta?$deltaToken=latest';

@Injectable({ providedIn: 'root' })
export class AzureDeltaService implements IDataService {
    constructor(
        private http: HttpClient,
        private store: Store,
        private db: AppDB,
        private featureService: FeatureService
    ) {}

    reloadData(): Observable<DataServiceResponse> {
        return forkJoin([
            this.db.clearUsers(),
            this.db.clearCategories(),
            this.db.clearUserPhotos(),
            this.clearDeltaLink()
        ]).pipe(switchMap(() => this.getData()));
    }

    getFreshData(users: Observable<DataServiceResponse>): Observable<DataServiceResponse> {
        this.store.dispatch(dataActions.loadUsersProgress({ progress: 5 }));
        return forkJoin([
            this.db.clearUsers(),
            this.db.clearCategories(),
            this.db.clearUserPhotos(),
            this.clearDeltaLink()
        ]).pipe(switchMap(() => users));
    }

    clearDeltaLink(): Observable<void> {
        return from(this.db.getMetadata()).pipe(
            map((metadata) => {
                this.db.saveMetadata({ ...metadata, deltaLink: null, id: 1 });
            })
        );
    }

    getData(): Observable<DataServiceResponse> {
        let count = 0;
        let progress = 100;
        const displayNameSplit$ = this.store.pipe(select(settingsFeature.selectDisplayNameSplit), take(1));
        const hasDirectoryAccess$ = this.featureService.hasFeature(Feature.Directory).pipe(take(1));

        const users = this.getCount().pipe(
            mergeMap((total) =>
                hasDirectoryAccess$.pipe(
                    switchMap((hasDirectoryAccess) => {
                        let directoryUrl = USERS_ENDPOINT_PARTIAL;
                        if (hasDirectoryAccess) {
                            directoryUrl = USERS_ENDPOINT_FULL;
                        }
                        return this.getDirectoryPage(directoryUrl).pipe(
                            expand((page) => {
                                console.log('next link', page['@odata.nextLink']);
                                return page['@odata.nextLink'] ? this.getDirectoryPage(page['@odata.nextLink']) : EMPTY;
                            }),
                            // Progress update
                            tap((page) => {
                                count += page.value.length;
                                if (total > 0) {
                                    progress = (90 / total) * count;
                                }
                                if (progress > 0) {
                                    console.log('progress 1', progress);
                                    this.store.dispatch(dataActions.loadUsersProgress({ progress }));
                                }
                            }),
                            // Caching here
                            mergeMap((page) =>
                                displayNameSplit$.pipe(
                                    mergeMap((displayNameSplit) =>
                                        from(
                                            this.db.instance.users.bulkPut(
                                                page.value.map((graphUser) =>
                                                    graphUserToDirectoryUser(graphUser, displayNameSplit)
                                                )
                                            )
                                        )
                                    ),
                                    mergeMap(() => {
                                        if (!page['@odata.nextLink']) {
                                            //get latest delta and update metadata
                                            console.log('getting latest delta');
                                            return this.getLatestDelta().pipe(
                                                mergeMap((latestDelta) => {
                                                    if (latestDelta['@odata.deltaLink']) {
                                                        console.log('updating metadata with latest delta');
                                                        return this.db.getMetadata().pipe(
                                                            map((metadata) =>
                                                                this.db.saveMetadata({
                                                                    ...metadata,
                                                                    deltaLink: latestDelta['@odata.deltaLink']
                                                                })
                                                            )
                                                        );
                                                    } else {
                                                        return of(null);
                                                    }
                                                })
                                            );
                                        }
                                        if (page['@odata.deltaLink']) {
                                            return this.db.getMetadata().pipe(
                                                map((metadata) =>
                                                    this.db.saveMetadata({
                                                        ...metadata,
                                                        deltaLink: page['@odata.deltaLink']
                                                    })
                                                )
                                            );
                                        } else {
                                            return of(null);
                                        }
                                    }),
                                    map(() => page),
                                    tap(() => console.log(`cached ${page.value.length} users`))
                                )
                            ),
                            toArray()
                        );
                    })
                )
            ),
            switchMap(() => this.db.getUsers()),
            map<DirectoryUser[], DataServiceResponse>((users) => ({
                users: users,
                dataSourceType: DataSourceType.Graph,
                isRefreshNeeded: true
            })),
            tap(() => {
                console.log('progress 2', progress);
                this.store.dispatch(dataActions.loadUsersProgress({ progress: 100 }));
            }),
            tap(() => this.store.dispatch(dataActions.hasRefreshedData())),
            //tap(() => this.store.dispatch(dataActions.rebuildCategoriesFromDbWithUsers())),
            map((response) => response)
        );
        const cached = this.db.getUsers().pipe(
            map((cachedUsers) => {
                const result: DataServiceResponse = {
                    users: cachedUsers,
                    dataSourceType: DataSourceType.Graph
                };
                this.store.dispatch(dataActions.loadUsersProgress({ progress: 100 }));
                return result;
            })
        );
        const metadata = this.db.getMetadata();
        const result = metadata.pipe(
            mergeMap((metadata) => {
                if (!metadata) {
                    console.log('No metadata found');
                    return this.getFreshData(users);
                }
                if (metadata.deltaLink) {
                    this.getDelta(metadata.deltaLink)
                        .pipe(
                            take(1),
                            mergeMap((deltaResponse) => {
                                if (deltaResponse.value.length === 0) {
                                    console.log('No changes detected');
                                    return of(null);
                                }
                                console.log(`Detected ${deltaResponse.value.length} changes`);
                                return this.getFreshData(users);
                            }),
                            catchError((error) => {
                                console.warn('Error getting delta', error);
                                return this.getFreshData(users);
                            })
                        )
                        .subscribe((users) => {
                            if (users) {
                                this.store.dispatch(dataActions.reloadUsersSuccess(users));
                            }
                        });
                    return cached;
                    // return this.getDelta(metadata.deltaLink).pipe(
                    //     mergeMap((deltaResponse) => {
                    //         if (deltaResponse.value.length === 0) {
                    //             console.log('No changes detected');
                    //             return cached;
                    //         }
                    //         console.log(`Detected ${deltaResponse.value.length} changes`);
                    //         return this.getFreshData(users);
                    //     }),
                    //     catchError((error) => {
                    //         console.warn('Error getting delta', error);
                    //         return this.getFreshData(users);
                    //     })
                    // );
                } else {
                    console.log('No delta link found');
                    return this.getFreshData(users);
                }
            })
        );
        return result;
        //return metadata;
    }

    getLatestDelta(): Observable<MsGraphDeltaResponse> {
        return this.http.get<MsGraphDeltaResponse>(USERS_LATEST_DELTA);
    }

    getDelta(deltaLink: string): Observable<MsGraphDeltaResponse> {
        this.store.dispatch(dataActions.loadUsersProgress({ progress: 5 }));
        return this.http.get<MsGraphDeltaResponse>(deltaLink);
    }

    getCount(): Observable<number> {
        let headers = new HttpHeaders();
        headers = headers.append('ConsistencyLevel', 'eventual');
        console.log('getting users count');
        return this.http.get<number>(USERS_COUNT, { headers }).pipe(map((countString) => Number(countString)));
    }

    getDirectoryPage(url: string = USERS_ENDPOINT_PARTIAL): Observable<MsGraphUsersResponse> {
        console.log('getting directory page', url);
        return this.http.get<MsGraphUsersResponse>(url);
        // return this.featureService.hasFeature(Feature.Directory).pipe(
        //     take(1),
        //     tap((hasFeature) => console.log('hasFeature', hasFeature)),
        //     switchMap((hasFeature) => {
        //         if (hasFeature) {
        //             console.log('getting full users data');
        //             return this.http.get<MsGraphUsersResponse>(USERS_ENDPOINT_FULL);
        //         } else {
        //             console.log('getting partial users data');
        //             return this.http.get<MsGraphUsersResponse>(url);
        //         }
        //     })
        // );
        //return this.http.get<MsGraphUsersResponse>(url);
    }

    getIndividualUsersData(users: DirectoryUser[]): Observable<DirectoryUser[]> {
        //each batch request is an object that has property "requests" and each request is an object with properties "id", "url", "method"
        /*{
            id: requestId++,
            url: requestUrl,
            method: 'GET'
        }*/
        //we need then to chain all batch requests one after another and return the result of all users returned by each individual request in each batch
        console.log('XXXXXXXXXXXXXXXXXXXXXXXXXX getting individual users data');
        const batchSize = 20;
        const batches = [];
        for (let i = 0; i < users.length; i += batchSize) {
            batches.push(users.slice(i, i + batchSize));
        }

        let requestId = 0;

        const batchRequests = batches.map((batch, index) => {
            const requests = batch.map((user) => ({
                id: requestId++,
                url: `/users/${user.id}?$select=birthday,employeeHireDate,displayName`, // use the $select operator to fetch birthday and hireDate
                method: 'GET'
            }));
            return this.http.post<MsGraphBatchUserResponse>(BATCH_REQUEST, { requests }); // replace with the correct batch endpoint
        });

        return concat(...batchRequests).pipe(
            toArray(),
            map((responses) => ([] as MsGraphBatchUserDetails[]).concat(...responses.map((x) => x.responses))), // flatten the array of arrays into a single array
            map((responses) =>
                responses.map((response) => {
                    //find user
                    //debugger;
                    const user = users[response.id];
                    //update user with new data
                    return { ...user, ...response.body };
                })
            )
        );
    }

    getDataSourceType(): DataSourceType {
        return DataSourceType.Graph;
    }
}
