import { sendMessageToParent as o } from "../internal/communication.js";
import { GlobalVars as t } from "../internal/globalVars.js";
import { registerHandler as e, removeHandler as r } from "../internal/handlers.js";
import { ensureInitialized as n } from "../internal/internalAPIs.js";
import { getApiVersionTag as i } from "../internal/telemetry.js";
import { isHostAdaptiveCardSchemaVersionUnsupported as a } from "../internal/utils.js";
import { FrameContexts as s, errorNotSupportedOnPlatform as l, DialogDimension as u } from "./constants.js";
import { runtime as d } from "./runtime.js";
const p = "v2";
function g(t, e) {
  if (n(d, s.content, s.sidePanel, s.task, s.meetingStage), !h.update.isSupported()) throw l;
  o(t, "tasks.updateTask", [e]);
}
function c(t, a, u, g) {
  if (n(d, s.content, s.sidePanel, s.meetingStage), !h.url.isSupported()) throw l;
  g && e(i(p, "dialog.url.registerMessageForParentHandler"), "messageForParent", g);
  const c = h.url.getDialogInfoFromUrlDialogInfo(a);
  o(t, "tasks.startTask", [c], (o, t) => {
    null == u || u({
      err: o,
      result: t
    }), r("messageForParent");
  });
}
function m(t, a, u, g) {
  if (n(d, s.content, s.sidePanel, s.meetingStage), !h.url.bot.isSupported()) throw l;
  g && e(i(p, "dialog.url.bot.registerMessageForParentHandler"), "messageForParent", g);
  const c = h.url.getDialogInfoFromBotUrlDialogInfo(a);
  o(t, "tasks.startTask", [c], (o, t) => {
    null == u || u({
      err: o,
      result: t
    }), r("messageForParent");
  });
}
function f(t, e, r) {
  if (n(d, s.task), !h.url.isSupported()) throw l;
  o(t, "tasks.completeTask", [e, r ? Array.isArray(r) ? r : [r] : []]);
}
var h;
!function (h) {
  const S = [];
  function C(o) {
    t.frameContext && (t.frameContext === s.task ? S.push(o) : r("messageForChild"));
  }
  var v;
  h.initialize = function () {
    e(i(p, "dialog.registerMessageForChildHandler"), "messageForChild", C, !1);
  }, function (t) {
    var a;
    function g(o) {
      return {
        url: o.url,
        height: o.size ? o.size.height : u.Small,
        width: o.size ? o.size.width : u.Small,
        title: o.title,
        fallbackUrl: o.fallbackUrl
      };
    }
    t.open = function (o, t, e) {
      c(i(p, "dialog.url.open"), o, t, e);
    }, t.submit = function (o, t) {
      f(i(p, "dialog.url.submit"), o, t);
    }, function (t) {
      function a() {
        var o, t;
        return n(d) && !!(null === (t = null === (o = d.supports.dialog) || void 0 === o ? void 0 : o.url) || void 0 === t ? void 0 : t.parentCommunication);
      }
      t.sendMessageToParentFromDialog = function (t) {
        if (n(d, s.task), !a()) throw l;
        o(i(p, "dialog.url.parentCommunication.sendMessageToParentFromDialog"), "messageForParent", [t]);
      }, t.sendMessageToDialog = function (t) {
        if (n(d, s.content, s.sidePanel, s.meetingStage), !a()) throw l;
        o(i(p, "dialog.url.parentCommunication.sendMessageToDialog"), "messageForChild", [t]);
      }, t.registerOnMessageFromParent = function (o) {
        if (n(d, s.task), !a()) throw l;
        for (r("messageForChild"), e(i(p, "dialog.url.parentCommunication.registerMessageForChildHandler"), "messageForChild", o), S.reverse(); S.length > 0;) {
          o(S.pop());
        }
      }, t.isSupported = a;
    }(t.parentCommunication || (t.parentCommunication = {})), t.isSupported = function () {
      return n(d) && void 0 !== (d.supports.dialog && d.supports.dialog.url);
    }, (a = t.bot || (t.bot = {})).open = function (o, t, e) {
      m(i(p, "dialog.url.bot.open"), o, t, e);
    }, a.isSupported = function () {
      return n(d) && void 0 !== (d.supports.dialog && d.supports.dialog.url && d.supports.dialog.url.bot);
    }, t.getDialogInfoFromUrlDialogInfo = g, t.getDialogInfoFromBotUrlDialogInfo = function (o) {
      const t = g(o);
      return t.completionBotId = o.completionBotId, t;
    };
  }(h.url || (h.url = {})), h.isSupported = function () {
    return !(!n(d) || !d.supports.dialog);
  }, (v = h.update || (h.update = {})).resize = function (o) {
    g(i(p, "dialog.update.resize"), o);
  }, v.isSupported = function () {
    return !(!n(d) || !d.supports.dialog || !d.supports.dialog.update);
  }, function (t) {
    function e() {
      const o = d.hostVersionsInfo && d.hostVersionsInfo.adaptiveCardSchemaVersion && !a(d.hostVersionsInfo.adaptiveCardSchemaVersion);
      return n(d) && void 0 !== (o && d.supports.dialog && d.supports.dialog.card);
    }
    function r(o) {
      return {
        card: o.card,
        height: o.size ? o.size.height : u.Small,
        width: o.size ? o.size.width : u.Small,
        title: o.title
      };
    }
    t.open = function (t, a) {
      if (n(d, s.content, s.sidePanel, s.meetingStage), !e()) throw l;
      const u = r(t);
      o(i(p, "dialog.adaptiveCard.open"), "tasks.startTask", [u], (o, t) => {
        null == a || a({
          err: o,
          result: t
        });
      });
    }, t.isSupported = e, function (t) {
      function e() {
        const o = d.hostVersionsInfo && d.hostVersionsInfo.adaptiveCardSchemaVersion && !a(d.hostVersionsInfo.adaptiveCardSchemaVersion);
        return n(d) && void 0 !== (o && d.supports.dialog && d.supports.dialog.card && d.supports.dialog.card.bot);
      }
      t.open = function (t, a) {
        if (n(d, s.content, s.sidePanel, s.meetingStage), !e()) throw l;
        const u = function (o) {
          const t = r(o);
          return t.completionBotId = o.completionBotId, t;
        }(t);
        o(i(p, "dialog.adaptiveCard.bot.open"), "tasks.startTask", [u], (o, t) => {
          null == a || a({
            err: o,
            result: t
          });
        });
      }, t.isSupported = e;
    }(t.bot || (t.bot = {}));
  }(h.adaptiveCard || (h.adaptiveCard = {}));
}(h || (h = {}));
export { m as botUrlOpenHelper, h as dialog, g as updateResizeHelper, c as urlOpenHelper, f as urlSubmitHelper };