import { sendAndHandleStatusAndReason as e, Communication as i, uninitializeCommunication as t, sendAndUnwrap as n, sendMessageToParent as a, initializeCommunication as o } from "../internal/communication.js";
import { defaultSDKVersionForCompatCheck as r } from "../internal/constants.js";
import { GlobalVars as s } from "../internal/globalVars.js";
import { registerOnThemeChangeHandler as l, registerBeforeSuspendOrTerminateHandler as m, registerOnResumeHandler as d, initializeHandlers as p, uninitializeHandlers as c } from "../internal/handlers.js";
import { ensureInitializeCalled as u, ensureInitialized as f, processAdditionalValidOrigins as g } from "../internal/internalAPIs.js";
import { getLogger as h, getApiVersionTag as y } from "../internal/telemetry.js";
import { isNullOrUndefined as S } from "../internal/typeCheckUtilities.js";
import { inServerSideRenderingEnvironment as I, runWithTimeout as C, compareSDKVersions as w } from "../internal/utils.js";
import { prefetchOriginsFromCDN as T } from "../internal/validOrigins.js";
import { messageChannels as v } from "../private/messageChannels.js";
import { authentication as P } from "./authentication.js";
import { FrameContexts as z, HostName as j, HostClientType as x } from "./constants.js";
import { dialog as O } from "./dialog.js";
import { menus as F } from "./menus.js";
import { pages as N } from "./pages.js";
import { runtime as b, applyRuntimeConfig as D, generateVersionBasedTeamsRuntimeConfig as L, mapTeamsVersionToSupportedCapabilities as E, versionAndPlatformAgnosticTeamsRuntimeConfig as A } from "./runtime.js";
import { version as k } from "./version.js";
const H = "v2",
  R = h("app");
function M(e, i) {
  if (I()) {
    return R.extend("initialize")("window object undefined at initialization"), Promise.resolve();
  }
  return C(() => function (e, i) {
    return new Promise(t => {
      s.initializeCalled || (s.initializeCalled = !0, p(), s.initializePromise = o(i, e).then(({
        context: e,
        clientType: i,
        runtimeConfig: t,
        clientSupportedSDKVersion: n = r
      }) => {
        s.frameContext = e, s.hostClientType = i, s.clientSupportedSDKVersion = n;
        try {
          W("Parsing %s", t);
          const e = JSON.parse(t);
          if (W("Checking if %o is a valid runtime object", null != e ? e : "null"), !e || !e.apiVersion) throw new Error("Received runtime config is invalid");
          t && D(e);
        } catch (e) {
          if (!(e instanceof SyntaxError)) throw e;
          try {
            W("Attempting to parse %s as an SDK version", t), isNaN(w(t, r)) || (s.clientSupportedSDKVersion = t);
            const e = JSON.parse(n);
            if (W("givenRuntimeConfig parsed to %o", null != e ? e : "null"), !e) throw new Error("givenRuntimeConfig string was successfully parsed. However, it parsed to value of null");
            D(e);
          } catch (e) {
            if (!(e instanceof SyntaxError)) throw e;
            D(L(s.clientSupportedSDKVersion, A, E));
          }
        }
        s.initializeCompleted = !0;
      }), P.initialize(), F.initialize(), N.config.initialize(), O.initialize()), Array.isArray(i) && g(i), void 0 !== s.initializePromise ? t(s.initializePromise) : W("GlobalVars.initializePromise is unexpectedly undefined");
    });
  }(e, i), 5e3, new Error("SDK initialization timed out."));
}
function V(e) {
  a(e, J.Messages.AppLoaded, [k]);
}
function U(e, i) {
  a(e, J.Messages.ExpectedFailure, [i.reason, i.message]);
}
function K(e, i) {
  a(e, J.Messages.Failure, [i.reason, i.message]);
}
function B(e) {
  a(e, J.Messages.Success, [k]);
}
const W = R.extend("initializeHelper");
function _(e, i) {
  !S(i) && u(), l(e, i);
}
function G(i, t) {
  return new Promise(n => {
    f(b, z.content, z.sidePanel, z.settings, z.task, z.stage, z.meetingStage), n(e(i, "executeDeepLink", t));
  });
}
var J;
!function (e) {
  const a = h("app");
  var o, r, l;
  e.Messages = {
    AppLoaded: "appInitialization.appLoaded",
    Success: "appInitialization.success",
    Failure: "appInitialization.failure",
    ExpectedFailure: "appInitialization.expectedFailure"
  }, (o = e.FailedReason || (e.FailedReason = {})).AuthFailed = "AuthFailed", o.Timeout = "Timeout", o.Other = "Other", (r = e.ExpectedFailureReason || (e.ExpectedFailureReason = {})).PermissionError = "PermissionError", r.NotFound = "NotFound", r.Throttling = "Throttling", r.Offline = "Offline", r.Other = "Other", e.isInitialized = function () {
    return s.initializeCompleted;
  }, e.getFrameContext = function () {
    return s.frameContext;
  }, a("teamsjs instance is version %s, starting at %s UTC (%s local)", k, new Date().toISOString(), new Date().toLocaleString()), function () {
    if (I()) return;
    const e = document.getElementsByTagName("script"),
      i = e && e[e.length - 1] && e[e.length - 1].src,
      t = "Today, teamsjs can only be used from a single script or you may see undefined behavior. This log line is used to help detect cases where teamsjs is loaded multiple times -- it is always written. The presence of the log itself does not indicate a multi-load situation, but multiples of these log lines will. If you would like to use teamjs from more than one script at the same time, please open an issue at https://github.com/OfficeDev/microsoft-teams-library-js/issues";
    i && 0 !== i.length ? a("teamsjs is being used from %s. %s", i, t) : a("teamsjs is being used from a script tag embedded directly in your html. %s", t);
  }(), e.initialize = function (e) {
    return T(), M(y(H, "app.initialize"), e);
  }, e._initialize = function (e) {
    i.currentWindow = e;
  }, e._uninitialize = function () {
    s.initializeCalled && (c(), s.initializeCalled = !1, s.initializeCompleted = !1, s.initializePromise = void 0, s.additionalValidOrigins = [], s.frameContext = void 0, s.hostClientType = void 0, s.isFramelessWindow = !1, v.telemetry._clearTelemetryPort(), v.dataLayer._clearDataLayerPort(), t());
  }, e.getContext = function () {
    return new Promise(e => {
      u(), e(n(y(H, "app.getContext"), "getContext"));
    }).then(e => function (e) {
      var i;
      const t = {
        actionInfo: e.actionInfo,
        app: {
          locale: e.locale,
          sessionId: e.appSessionId ? e.appSessionId : "",
          theme: e.theme ? e.theme : "default",
          iconPositionVertical: e.appIconPosition,
          osLocaleInfo: e.osLocaleInfo,
          parentMessageId: e.parentMessageId,
          userClickTime: e.userClickTime,
          userFileOpenPreference: e.userFileOpenPreference,
          host: {
            name: e.hostName ? e.hostName : j.teams,
            clientType: e.hostClientType ? e.hostClientType : x.web,
            sessionId: e.sessionId ? e.sessionId : "",
            ringId: e.ringId
          },
          appLaunchId: e.appLaunchId
        },
        page: {
          id: e.entityId,
          frameContext: e.frameContext ? e.frameContext : s.frameContext,
          subPageId: e.subEntityId,
          isFullScreen: e.isFullScreen,
          isMultiWindow: e.isMultiWindow,
          isBackgroundLoad: e.isBackgroundLoad,
          sourceOrigin: e.sourceOrigin
        },
        user: {
          id: null !== (i = e.userObjectId) && void 0 !== i ? i : "",
          displayName: e.userDisplayName,
          isCallingAllowed: e.isCallingAllowed,
          isPSTNCallingAllowed: e.isPSTNCallingAllowed,
          licenseType: e.userLicenseType,
          loginHint: e.loginHint,
          userPrincipalName: e.userPrincipalName,
          tenant: e.tid ? {
            id: e.tid,
            teamsSku: e.tenantSKU
          } : void 0
        },
        channel: e.channelId ? {
          id: e.channelId,
          displayName: e.channelName,
          relativeUrl: e.channelRelativeUrl,
          membershipType: e.channelType,
          defaultOneNoteSectionId: e.defaultOneNoteSectionId,
          ownerGroupId: e.hostTeamGroupId,
          ownerTenantId: e.hostTeamTenantId
        } : void 0,
        chat: e.chatId ? {
          id: e.chatId
        } : void 0,
        meeting: e.meetingId ? {
          id: e.meetingId
        } : void 0,
        sharepoint: e.sharepoint,
        team: e.teamId ? {
          internalId: e.teamId,
          displayName: e.teamName,
          type: e.teamType,
          groupId: e.groupId,
          templateId: e.teamTemplateId,
          isArchived: e.isTeamArchived,
          userRole: e.userTeamRole
        } : void 0,
        sharePointSite: e.teamSiteUrl || e.teamSiteDomain || e.teamSitePath || e.mySitePath || e.mySiteDomain ? {
          teamSiteUrl: e.teamSiteUrl,
          teamSiteDomain: e.teamSiteDomain,
          teamSitePath: e.teamSitePath,
          teamSiteId: e.teamSiteId,
          mySitePath: e.mySitePath,
          mySiteDomain: e.mySiteDomain
        } : void 0,
        dialogParameters: e.dialogParameters || {}
      };
      return t;
    }(e));
  }, e.notifyAppLoaded = function () {
    u(), V(y(H, "app.notifyAppLoaded"));
  }, e.notifySuccess = function () {
    u(), B(y(H, "app.notifySuccess"));
  }, e.notifyFailure = function (e) {
    u(), K(y(H, "app.notifyFailure"), e);
  }, e.notifyExpectedFailure = function (e) {
    u(), U(y(H, "app.notifyExpectedFailure"), e);
  }, e.registerOnThemeChangeHandler = function (e) {
    _(y(H, "app.registerOnThemeChangeHandler"), e);
  }, e.openLink = function (e) {
    return G(y(H, "app.openLink"), e);
  }, (l = e.lifecycle || (e.lifecycle = {})).registerBeforeSuspendOrTerminateHandler = function (e) {
    if (!e) throw new Error("[app.lifecycle.registerBeforeSuspendOrTerminateHandler] Handler cannot be null");
    f(b), m(e);
  }, l.registerOnResumeHandler = function (e) {
    if (!e) throw new Error("[app.lifecycle.registerOnResumeHandler] Handler cannot be null");
    f(b), d(e);
  };
}(J || (J = {}));
export { J as app, M as appInitializeHelper, V as notifyAppLoadedHelper, U as notifyExpectedFailureHelper, K as notifyFailureHelper, B as notifySuccessHelper, G as openLinkHelper, _ as registerOnThemeChangeHandlerHelper };