import { sendAndUnwrap as t, sendMessageToParent as e } from "../internal/communication.js";
import { errorCallNotStarted as n } from "../internal/constants.js";
import { createTeamsDeepLinkForCall as r } from "../internal/deepLinkUtilities.js";
import { ensureInitialized as i } from "../internal/internalAPIs.js";
import { getApiVersionTag as o } from "../internal/telemetry.js";
import { FrameContexts as a, errorNotSupportedOnPlatform as s } from "./constants.js";
import { runtime as l } from "./runtime.js";
var m;
!function (m) {
  let u;
  function c() {
    return !(!i(l) || !l.supports.call);
  }
  !function (t) {
    t.Unknown = "unknown", t.Audio = "audio", t.Video = "video", t.VideoBasedScreenSharing = "videoBasedScreenSharing", t.Data = "data";
  }(u = m.CallModalities || (m.CallModalities = {})), m.startCall = function (m) {
    const d = o("v2", "call.startCall");
    return new Promise(o => {
      var f;
      if (i(l, a.content, a.task), !c()) throw s;
      if (!l.isLegacyTeams) return e(d, "call.startCall", [m], o);
      o(t(d, "executeDeepLink", r(m.targets, null === (f = m.requestedModalities) || void 0 === f ? void 0 : f.includes(u.Video), m.source)).then(t => {
        if (!t) throw new Error(n);
        return t;
      }));
    });
  }, m.isSupported = c;
}(m || (m = {}));
export { m as call };