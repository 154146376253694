import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, filter, Subject, takeUntil, throttleTime } from 'rxjs';
import * as fromRoot from '../../reducers';
import * as dataActions from '../../data/data.actions';
import { Router } from '@angular/router';

@Component({
    selector: 'dir-search-form',
    templateUrl: './search-form.component.html',
    styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit, OnDestroy {
    @Input() searchString$: Subject<string>;
    private readonly unsubscribe$ = new Subject<void>();
    searchInput = new FormControl<string>('');

    showSearch = true;
    constructor(private store: Store, private router: Router) {}

    ngOnInit(): void {
        this.store
            .pipe(takeUntil(this.unsubscribe$), select(fromRoot.selectSearchString) /*, take(1)*/)
            .subscribe((searchString) => this.searchInput.setValue(searchString));
        this.searchInput.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((x) => x != null),
                throttleTime(300, null, { leading: true, trailing: true }),
                distinctUntilChanged()
            )
            .subscribe((searchString) => this.store.dispatch(dataActions.updateSearchString({ searchString })));

        this.showSearch = this.router && !this.router.url.toLowerCase().startsWith('/settings') && !this.router.url.toLowerCase().startsWith('/integrity');
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
