import { hasScriptTags as t } from "./utils.js";
function i(i) {
  if (t(i)) throw new Error(`Potential app id (${i}) is invalid; it contains script tags.`);
  if (!o(i)) throw new Error(`Potential app id (${i}) is invalid; its length ${i.length} is not within the length limits (${n}-${r}).`);
  if (e(i)) throw new Error(`Potential app id (${i}) is invalid; it contains non-printable characters.`);
}
const n = 4,
  r = 256;
function o(t) {
  return t.length < r && t.length > n;
}
function e(t) {
  return [...t].some(t => {
    const i = t.charCodeAt(0);
    return i < 32 || i > 126;
  });
}
export { e as doesStringContainNonPrintableCharacters, o as isStringWithinAppIdLengthLimits, r as maximumValidAppIdLength, n as minimumValidAppIdLength, i as validateStringAsAppId };