import { createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import {
    ModifiedAccountInfo,
    SubscriptionStatus,
    TeamsEnvironment,
    TenantAccount,
    UserPermissionType
} from './auth.models';
import * as SettingsActions from '../settings/settings.actions';

export const authFeatureKey = 'auth';

export interface State {
    user: ModifiedAccountInfo;
    isTeams: boolean;
    teamsEnvironment: TeamsEnvironment;
    isDemo: boolean;
    tenantAccount: TenantAccount;
    isTrial: boolean;
    isTeamsInteractionInProgress: boolean;
    hasFailedToLoadTenant: boolean;
}

export const initialState: State = {
    user: null,
    isTeams: false,
    teamsEnvironment: null,
    isDemo: false,
    isTrial: false,
    tenantAccount: null,
    isTeamsInteractionInProgress: false,
    hasFailedToLoadTenant: false
};

export const reducer = createReducer(
    initialState,
    on(AuthActions.setIsTeams, (state, action) => ({ ...state, isTeams: action.isTeams })),
    on(AuthActions.setTeamsEnvironment, (state, { teamsEnvironment }) => ({
        ...state,
        teamsEnvironment: state.teamsEnvironment ? { ...state.teamsEnvironment, ...teamsEnvironment } : teamsEnvironment
    })),
    on(AuthActions.msalLoginSuccess, AuthActions.msalLoadLoggedInUser, (state, { data }) => ({
        ...state,
        user: data
    })),
    on(AuthActions.loadTenantSuccess, (state, { tenantAccount }) => ({
        ...state,
        tenantAccount,
        hasFailedToLoadTenant: false,
        isTrial: tenantAccount.subscriptionStatus === SubscriptionStatus.Trial
    })),
    on(AuthActions.loadTenantFailure, (state) => ({ ...state, hasFailedToLoadTenant: true })),
    on(AuthActions.setIsDemo, (state, { isDemo }) => ({ ...state, isDemo })),
    on(AuthActions.startTeamsInteraction, (state) => ({ ...state, isTeamsInteractionInProgress: true })),
    on(AuthActions.endTeamsInteraction, (state) => ({ ...state, isTeamsInteractionInProgress: false })),
    on(
        SettingsActions.loadAdminsSuccess,
        SettingsActions.deleteAdminSuccess,
        SettingsActions.saveAdminSuccess,
        (state, action) => {
            if (state.tenantAccount) {
                return {
                    ...state,
                    tenantAccount: { ...state.tenantAccount, isNoAdmins: !(action.admins?.length > 0) }
                };
            }
            return state;
        }
    )
);

export const selectUser = (state: State) => state.user;
export const selectIsTeams = (state: State) => state.isTeams;
export const selectTeamsEnvironment = (state: State) => state.teamsEnvironment;
export const selectTenantAccount = (state: State) => state.tenantAccount;
export const selectIsTrial = (state: State) => state.isTrial;
export const selectIsDemo = (state: State) => state.isDemo;
export const selectSubscriptionStatus = (state: State) =>
    state.tenantAccount ? state.tenantAccount.subscriptionStatus : SubscriptionStatus.Trial;
export const selectUserObjectId = (state: State) => (state.user ? (state.user.localAccountId as string) : '');
export const selectUserIsAdmin = (state: State) => {
    //console.warn('selectUserIsAdmin', state.tenantAccount);
    return state.tenantAccount ? state.tenantAccount.userPermission.permissionType === UserPermissionType.Admin : false;
};
export const selectIsNoAdmins = (state: State) => {
    return !!state.tenantAccount?.isNoAdmins;
};
export const selectIsTeamsInteractionInProgress = (state: State) => state.isTeamsInteractionInProgress;
export const selectHasFailedToLoadTenant = (state: State) => state.hasFailedToLoadTenant;
