import { Directive, HostListener, HostBinding, EventEmitter, Output } from '@angular/core';

@Directive({
    selector: '[dirDropZone]'
})
export class DropZoneDirective {
    @HostBinding('class.fileover') fileOver = false;
    @Output() fileDropped = new EventEmitter<File>();
    file: File = null;

    @HostListener('dragover', ['$event']) onDragOver(event) {
        event.preventDefault();
        event.stopPropagation();
        this.fileOver = true;
    }

    @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.fileOver = false;
        this.file = event.dataTransfer.files[0];
        if (this.file != null) {
            this.fileDropped.emit(this.file);
        }
    }

    @HostListener('dragleave', ['$event']) onDragLeave(event) {
        event.preventDefault();
        event.stopPropagation();
        this.fileOver = false;
    }
}
