import { sendMessageToParentAsync as t, Communication as e, sendMessageEventToChild as n, sendMessageToParent as i, waitForMessageQueue as r } from "../internal/communication.js";
import { GlobalVars as a } from "../internal/globalVars.js";
import { registerHandler as o, removeHandler as c } from "../internal/handlers.js";
import { ensureInitialized as l, ensureInitializeCalled as u } from "../internal/internalAPIs.js";
import { getApiVersionTag as s } from "../internal/telemetry.js";
import { fullyQualifyUrlString as h, validateUrl as d } from "../internal/utils.js";
import { FrameContexts as f, HostClientType as w } from "./constants.js";
import { runtime as m } from "./runtime.js";
const g = "v1",
  b = "v2";
var W;
!function (W) {
  let C, k, y;
  function p() {
    v();
    try {
      e.childWindow && e.childWindow.close();
    } finally {
      e.childWindow = null, e.childOrigin = null;
    }
  }
  function v() {
    k && (clearInterval(k), k = 0), c("initialize"), c("navigateCrossDomain");
  }
  function T(t) {
    try {
      C && C.success(t);
    } finally {
      C = void 0, p();
    }
  }
  function j(t) {
    try {
      C && C.fail(new Error(t));
    } finally {
      C = void 0, p();
    }
  }
  var A;
  W.initialize = function () {
    o(s(g, "authentication.registerAuthenticateSuccessHandler"), "authentication.authenticate.success", T, !1), o(s(g, "authentication.registerAuthenticateFailureHandler"), "authentication.authenticate.failure", j, !1);
  }, W.registerAuthenticationHandlers = function (t) {
    y = t;
  }, W.authenticate = function (i) {
    const r = void 0 !== i,
      c = r ? i : y;
    if (!c) throw new Error("No parameters are provided for authentication");
    return l(m, f.content, f.sidePanel, f.settings, f.remove, f.task, f.stage, f.meetingStage), function (i, r) {
      return new Promise((c, l) => {
        if (a.hostClientType !== w.web) {
          const e = h(r.url);
          d(e), c(t(i, "authentication.authenticate", [e.href, r.width, r.height, r.isExternal]).then(([t, e]) => {
            if (t) return e;
            throw new Error(e);
          }));
        } else C = {
          success: c,
          fail: l
        }, function (t) {
          p();
          let i = t.width || 600,
            r = t.height || 400;
          i = Math.min(i, e.currentWindow.outerWidth - 400), r = Math.min(r, e.currentWindow.outerHeight - 200);
          const c = h(t.url.replace("{oauthRedirectMethod}", "web"));
          d(c);
          let l = void 0 !== e.currentWindow.screenLeft ? e.currentWindow.screenLeft : e.currentWindow.screenX,
            u = void 0 !== e.currentWindow.screenTop ? e.currentWindow.screenTop : e.currentWindow.screenY;
          l += e.currentWindow.outerWidth / 2 - i / 2, u += e.currentWindow.outerHeight / 2 - r / 2, e.childWindow = e.currentWindow.open(c.href, "_blank", "toolbar=no, location=yes, status=no, menubar=no, scrollbars=yes, top=" + u + ", left=" + l + ", width=" + i + ", height=" + r), e.childWindow ? (v(), k = e.currentWindow.setInterval(() => {
            if (!e.childWindow || e.childWindow.closed) j("CancelledByUser");else {
              const t = e.childOrigin;
              try {
                e.childOrigin = "*", n("ping");
              } finally {
                e.childOrigin = t;
              }
            }
          }, 100), o(s(g, "authentication.authenticationWindow.registerInitializeHandler"), "initialize", () => [f.authentication, a.hostClientType]), o(s(g, "authentication.authenticationWindow.registerNavigateCrossDomainHandler"), "navigateCrossDomain", () => !1)) : j("FailedToOpenWindow");
        }(r);
      });
    }(c.successCallback || c.failureCallback ? s(g, "authentication.authenticate") : s(b, "authentication.authenticate"), c).then(t => {
      try {
        return c && c.successCallback ? (c.successCallback(t), "") : t;
      } finally {
        r || (y = void 0);
      }
    }).catch(t => {
      try {
        if (c && c.failureCallback) return c.failureCallback(t.message), "";
        throw t;
      } finally {
        r || (y = void 0);
      }
    });
  }, W.getAuthToken = function (e) {
    return u(), function (e, n) {
      return new Promise(i => {
        i(t(e, "authentication.getAuthToken", [null == n ? void 0 : n.resources, null == n ? void 0 : n.claims, null == n ? void 0 : n.silent, null == n ? void 0 : n.tenantId]));
      }).then(([t, e]) => {
        if (t) return e;
        throw new Error(e);
      });
    }(e && (e.successCallback || e.failureCallback) ? s(g, "authentication.getAuthToken") : s(b, "authentication.getAuthToken"), e).then(t => e && e.successCallback ? (e.successCallback(t), "") : t).catch(t => {
      if (e && e.failureCallback) return e.failureCallback(t.message), "";
      throw t;
    });
  }, W.getUser = function (e) {
    return u(), function (e) {
      return new Promise(n => {
        n(t(e, "authentication.getUser"));
      }).then(([t, e]) => {
        if (t) return e;
        throw e;
      });
    }(e && (e.successCallback || e.failureCallback) ? s(g, "authentication.getUser") : s(b, "authentication.getUser")).then(t => e && e.successCallback ? (e.successCallback(t), null) : t).catch(t => {
      const n = `Error returned, code = ${t.errorCode}, message = ${t.message}`;
      if (e && e.failureCallback) return e.failureCallback(n), null;
      throw new Error(n);
    });
  }, W.notifySuccess = function (t, n) {
    l(m, f.authentication);
    const a = s(n ? g : b, "authentication.notifySuccess");
    i(a, "authentication.authenticate.success", [t]), r(e.parentWindow, () => setTimeout(() => e.currentWindow.close(), 200));
  }, W.notifyFailure = function (t, n) {
    l(m, f.authentication);
    const a = s(n ? g : b, "authentication.notifyFailure");
    i(a, "authentication.authenticate.failure", [t]), r(e.parentWindow, () => setTimeout(() => e.currentWindow.close(), 200));
  }, (A = W.DataResidency || (W.DataResidency = {})).Public = "public", A.EUDB = "eudb", A.Other = "other";
}(W || (W = {}));
export { W as authentication };