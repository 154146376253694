import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import * as fromRoot from '../../reducers';
import { UserPresence } from '../availability.models';

@Component({
    selector: 'dir-user-presence',
    templateUrl: './user-presence.component.html',
    styleUrls: ['./user-presence.component.scss']
})
export class UserPresenceComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    @Input() id = '';

    @Input() mode: 'line' | 'square' | 'square-xl' | 'circle' = 'line'; // Controls the drawing mode, line, square or square-xl.

    presence = 'available';
    up: UserPresence = null;
    constructor(private store: Store) {}

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngOnInit(): void {
        this.store.pipe(takeUntil(this.unsubscribe$), select(fromRoot.selectUserPresence(this.id))).subscribe((up) => {
            if (up) {
                this.presence = up.availability.toLowerCase();
                this.up = up;
            }
        });
    }
}
