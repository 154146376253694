<!--begin::Topbar-->
<div class="topbar">
    <!--begin::Topbar container-->
    <div
        class="container-fluid py-6 py-lg-0 px-9 d-flex flex-row flex-sm-row align-items-lg-stretch justify-content-sm-between">
        <!--begin::Mobile menu toggle-->
        <div *ngIf="!isTeamsChannel" class="d-flex align-items-center d-lg-none ms-n2 me-2" title="Show sidebar menu">
            <div role="button" tabindex="0" class="btn btn-icon btn-active-color-primary w-35px h-35px" id="kt_app_sidebar_mobile_toggle"
                (click)="openDrawer()" (keydown.space)="openDrawer()">
                <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
                <span class="svg-icon svg-icon-1">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                            fill="currentColor" />
                        <path opacity="0.3"
                            d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                            fill="currentColor" />
                    </svg>
                </span>
                <!--end::Svg Icon-->
            </div>
        </div>
        <button *ngIf="teamsTabUrl" class="btn btn-primary" (click)="goHome()"><i
                class="bi bi-house-fill fs-4 me-2"></i> Home</button>
        <dir-search-form *ngIf="(isDirectoryAvailable$|async) && !isTeamsChannel" class="flex-grow-1"></dir-search-form>
        <div *ngIf="(isDirectoryAvailable$|async) === false || isTeamsChannel" class="card2"></div>
        <!--begin::Action group-->
        <div class="d-flex align-items-center pt-0 ms-2 ms-sm-2 ms-lg-0">
            <!--begin::Actions-->
            <div class="d-flex">
                <!--begin::Notifications-->
                <div *ngIf="(isDirectoryAvailable$|async)" class="d-flex align-items-center me-md-2 me-sm-1">
                    <!--begin::Menu- wrapper-->
                    <button (click)="onReload()" [tooltip]="isTeamsMobile ? '' : 'Reload data'" [disabled]="!online"
                        class="btn btn-icon btn-active-light btn-outline btn-outline-default btn-icon-gray-700 btn-active-icon-primary">
                        <i class="bi bi-arrow-clockwise fs-2"></i>
                    </button>
                    <!--end::Menu wrapper-->
                </div>
                <!--end::Notifications-->
                <div class="me-md-2 me-sm-1">
                    <button [matMenuTriggerFor]="themeMenu"
                        class="btn btn-icon btn-active-light btn-outline btn-outline-default btn-icon-gray-muted btn-active-icon-primary">
                        <i class="ki-duotone ki-night-day theme-light-show fs-1">
                            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span
                                class="path4"></span><span class="path5"></span><span class="path6"></span><span
                                class="path7"></span><span class="path8"></span><span class="path9"></span><span
                                class="path10"></span>
                        </i>
                        <i class="ki-duotone ki-moon theme-dark-show fs-1">
                            <span class="path1"></span>
                            <span class="path2"></span>
                        </i>
                    </button>
                    <mat-menu #themeMenu="matMenu">
                        <button (click)="onThemeChange('light')" mat-menu-item
                            class="d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <i class="ki-duotone ki-night-day fs-2 text-gray-800 me-2">
                                    <span class="path1"></span><span class="path2"></span><span
                                        class="path3"></span><span class="path4"></span><span class="path5"></span><span
                                        class="path6"></span><span class="path7"></span><span class="path8"></span><span
                                        class="path9"></span><span class="path10"></span>
                                </i><span>Light</span>
                            </div>
                        </button>
                        <button (click)="onThemeChange('dark')" mat-menu-item
                            class="d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <i class="ki-duotone ki-moon fs-2 text-gray-800 me-2">
                                    <span class="path1"></span>
                                    <span class="path2"></span>
                                </i><span>Dark</span>
                            </div>
                        </button>
                        <button (click)="onThemeChange('auto')" mat-menu-item>
                            <div class="d-flex align-items-center">
                                <i class="ki-duotone ki-screen fs-2 text-gray-800 me-2"><span class="path1"></span><span
                                        class="path2"></span><span class="path3"></span><span class="path4"></span>
                                </i><span>System</span>
                            </div>
                        </button>
                    </mat-menu>
                </div>
                <div class="d-flex align-items-center me-md-2 me-sm-1">
                    <!--begin::Menu- wrapper-->
                    <a href="https://help.team-directory.com/" target="team-directory-help" tooltip="TeamDirectory Guide"
                        class="btn btn-icon btn-active-light btn-outline btn-outline-default btn-icon-gray-700 btn-active-icon-primary">
                        <i class="bi bi-question-circle fs-1"></i>
                </a>
                    <!--end::Menu wrapper-->
                </div>
                <!--begin::Theme mode-->
                <div *ngIf="!isTeamsMobile && !hostedInTOC" class="d-flex align-items-center">
                    <!--begin::Theme mode docs-->
                    <button [matMenuTriggerFor]="menu"
                        class="btn btn-icon btn-active-light btn-outline btn-outline-default btn-icon-gray-muted btn-active-icon-primary">
                        <dir-graph-picture *ngIf="userObjectId && (isDirectoryAvailable$|async)" [edgeStyle]="'circle'"
                            [id]="userObjectId" [size]="35" [isCurrentUser]="false"
                            [showDefaultPicture]="(isDirectoryAvailable$|async) === false" class="symbol-sm-35px"
                            [classes]="">
                        </dir-graph-picture>
                        <div *ngIf="(isDirectoryAvailable$|async) === false">
                            <span> <i class="bi bi-person-circle fs-2 text-gray-800"></i></span>
                        </div>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button *ngIf="!isTeams" (click)="onLogout()" mat-menu-item
                            class="d-flex align-items-center justify-content-between">
                            <i class="bi bi-box-arrow-right fs-2 text-gray-800"></i> Sign out
                        </button>
                        <button (click)="onRefresh()" mat-menu-item
                            class="d-flex align-items-center justify-content-between">
                            <i class="bi bi-arrow-clockwise fs-2 text-gray-800"></i> Refresh Permission
                        </button>
                        <button mat-menu-item class="menu-divider" disabled>{{userName}}</button>
                    </mat-menu>
                    <!--end::Theme mode docs-->
                </div>
                <!--end::Theme mode-->
            </div>
            <!--end::Actions-->
        </div>
        <!--end::Action group-->
    </div>
    <!--end::Topbar container-->
</div>
<!--end::Topbar-->
