import { InjectionToken } from '@angular/core';
import {
    createSelector,
    createFeatureSelector,
    ActionReducerMap,
    Action,
    ActionReducer,
    MetaReducer
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import * as fromRouter from '@ngrx/router-store';
import { defaultMergeReducer, localStorageSync } from 'ngrx-store-localstorage';
import * as fromAuth from '../auth/auth.reducer';
import * as fromData from '../data/data.reducer';
import * as fromUi from '../ui/ui.reducer';
import * as fromUserAvailability from '../userAvailability/availability.reducer';
import { integrityFeature } from '../integrity/integrity.reducer';
import { uiFeature } from '../ui/ui.reducer';

import { SettingsTab, ViewType } from '../app.models';
import { CategorySearchResults, DisplayedUsersGroup, SearchResults, SortOrder } from '../data/data.models';
import { ColumnType } from '../data/categories';
import { getSortFieldValue } from '../data/sort';
import { logger } from '../utils/logger';
import { RouterStateSnapshot } from '@angular/router';
/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */
//import * as fromLayout from '@example-app/core/reducers/layout.reducer';
/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
    [fromAuth.authFeatureKey]: fromAuth.State;
    [fromData.dataFeatureKey]: fromData.State;
    //[fromSettings.settingsFeatureKey]: fromSettings.State;
    router: fromRouter.RouterReducerState<RouterStateSnapshot>;
    [fromUi.uiFeatureKey]: fromUi.State;
    [fromUserAvailability.userPresenceFeatureKey]: fromUserAvailability.AvailabilityState;
    //[fromIntegrity.integrityFeatureKey]: fromIntegrity.State;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const ROOT_REDUCERS = new InjectionToken<ActionReducerMap<State, Action>>('Root reducers token', {
    factory: () => ({
        auth: fromAuth.reducer,
        data: fromData.reducer,
        //settings: fromSettings.reducer,
        router: fromRouter.routerReducer,
        ui: fromUi.reducer,
        userAvailability: fromUserAvailability.reducer
        //integrity: fromIntegrity.integrityFeature.reducer
    })
});

// console.log all actions
export function loggerCallback(reducer: ActionReducer<State>): ActionReducer<State> {
    return (state, action) => {
        const result = reducer(state, action);
        //ngxLogger?.log(action.type, action);
        console.groupCollapsed(action.type);
        console.log('prev state', state);
        console.log('action', action);
        console.log('next state', result);
        console.groupEnd();
        // if (logger && false) {
        //     logger.debug(action.type);
        //     logger.debug('prev state', state);
        //     logger.debug('action', action);
        //     logger.debug('next state', result);
        // }

        return result;
    };
}

// sync some slices with local storage
function localStorageSyncReducer(reducer: ActionReducer<State>): ActionReducer<State> {
    return localStorageSync({
        keys: [
            {
                data: [
                    'usersPageSize',
                    'usersViewMode',
                    'categoryItemsViewModes',
                    'categorySortFields',
                    'categorySortOrders',
                    'expandedCategoriesIds',
                    'sortField',
                    'sortOrder',
                    'groupBy'
                ]
            },
            {
                settings: ['officesFieldName']
            },
            {
                auth: ['isTrial']
            },
            {
                ui: ['theme']
            }
        ],
        storageKeySerializer: (key) => `directory_store_${key}`,
        rehydrate: true,
        mergeReducer: (existingState, rehydratedState, action) => {
            //remove rehydratedState top level keys that are not in existingState using Object.keys
            const definedRehydratedState = Object.keys(rehydratedState).reduce((acc, key) => {
                if (existingState[key] != null) {
                    acc[key] = rehydratedState[key];
                }
                return acc;
            }, {});
            return defaultMergeReducer(existingState, definedRehydratedState, action);
        }
    })(reducer);
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = !environment.production
    ? [localStorageSyncReducer, loggerCallback]
    : [localStorageSyncReducer, loggerCallback];

/**
 * Layout Selectors
 */
export const selectAuthState = createFeatureSelector<fromAuth.State>(fromAuth.authFeatureKey);
export const selectUser = createSelector(selectAuthState, fromAuth.selectUser);
export const selectIsTeams = createSelector(selectAuthState, fromAuth.selectIsTeams);
export const selectIsTeamsInteractionInProgress = createSelector(
    selectAuthState,
    fromAuth.selectIsTeamsInteractionInProgress
);
export const selectTeamsEnvironment = createSelector(selectAuthState, fromAuth.selectTeamsEnvironment);
export const selectIsDemo = createSelector(selectAuthState, fromAuth.selectIsDemo);
export const selectTenantAccount = createSelector(selectAuthState, fromAuth.selectTenantAccount);
export const selectIsTrial = createSelector(selectAuthState, fromAuth.selectIsTrial);
export const selectSubscriptionStatus = createSelector(selectAuthState, fromAuth.selectSubscriptionStatus);
export const selectUserObjectId = createSelector(selectAuthState, fromAuth.selectUserObjectId);
export const selectUserIsAdmin = createSelector(selectAuthState, fromAuth.selectUserIsAdmin);
export const selectIsNoAdmins = createSelector(selectAuthState, fromAuth.selectIsNoAdmins);
export const selectHasFailedToLoadTenant = createSelector(selectAuthState, fromAuth.selectHasFailedToLoadTenant);
/**
 * Data selectors
 */
export const selectDataState = createFeatureSelector<fromData.State>(fromData.dataFeatureKey);
export const selectUsersUnsorted = createSelector(selectDataState, fromData.selectUsersUnsorted);
export const selectUsersRaw = createSelector(selectDataState, fromData.selectUsersRaw);
export const selectUsersRawButFiltered = createSelector(selectDataState, fromData.selectUsersRawButFiltered);
export const selectUsersPageSize = createSelector(selectDataState, fromData.selectUsersPageSize);
export const selectUserColumnSettings = createSelector(selectDataState, fromData.selectUserColumnSettings);
export const selectUserModifications = createSelector(selectDataState, fromData.selectUserModifications);
export const selectProgress = createSelector(selectDataState, fromData.selectProgress);
export const selectDataSourceType = createSelector(selectDataState, fromData.selectDataSourceType);
export const selectSavedCategories = createSelector(selectDataState, fromData.selectSavedCategories);
export const selectSavedCategoryItems = createSelector(selectDataState, fromData.selectSavedCategoryItems);
export const selectCategories = createSelector(selectDataState, fromData.selectMergedCategories);
export const selectUsersPageNumber = createSelector(selectDataState, fromData.selectUsersPageNumber);
export const selectUsersViewMode = createSelector(selectDataState, fromData.selectUsersViewMode);
export const selectCategoryItemsViewModes = createSelector(selectDataState, fromData.selectCategoryItemsViewModes);
export const selectCategorySortFields = createSelector(selectDataState, fromData.selectCategorySortFields);
export const selectCategorySortOrders = createSelector(selectDataState, fromData.selectCategorySortOrders);
export const selectExpandedCategoriesIds = createSelector(selectDataState, fromData.selectExpandedCategoriesIds);
export const selectOpenedModalNames = createSelector(selectDataState, fromData.selectOpenedModalNames);
export const selectOpenedModalOptions = createSelector(selectDataState, fromData.selectOpenedModalOptions);
export const selectSortField = createSelector(selectDataState, fromData.selectSortField);
export const selectSortOrder = createSelector(selectDataState, fromData.selectSortOrder);
export const selectGroupBy = createSelector(selectDataState, fromData.selectGroupBy);
export const selectIsSavingFromDialog = createSelector(selectDataState, fromData.selectIsSavingFromDialog);
export const selectHasLoadedDataFromDb = createSelector(selectDataState, fromData.selectHasLoadedDataFromDb);
export const selectHasFailedToGetDirectoryData = createSelector(
    selectDataState,
    fromData.selectHasFailedToGetDirectoryData
);
export const selectFilters = createSelector(selectDataState, fromData.selectFilters);
export const selectFavorites = createSelector(selectDataState, fromData.selectFavorites);
export const selectFilterLetter = createSelector(selectDataState, fromData.selectFilterLetter);
export const selectHasLoadDataFromDbFailed = createSelector(selectDataState, fromData.selectHasLoadDataFromDbFailed);
export const selectSelectedDataSourceId = createSelector(selectDataState, fromData.selectSelectedDataSourceId);
export const selectIsEntraSource = createSelector(selectDataState, fromData.selectIsEntraSource);
export const selectSelectedDataSourceChartId = createSelector(
    selectDataState,
    fromData.selectSelectedDataSourceChartId
);
export const selectSelectedDataSourceIdAndChartId = createSelector(
    selectSelectedDataSourceId,
    selectSelectedDataSourceChartId,
    (dataSourceId, chartId) => {
        return {
            dataSourceId,
            chartId
        };
    }
);
export const selectDataSourceInfo = createSelector(
    selectSelectedDataSourceChartId,
    selectSelectedDataSourceId,
    (chartId, dataSourceId) => {
        return {
            chartId: chartId ?? '',
            dataSourceId: dataSourceId ?? -1
        };
    }
);
export const selectHasDataSourceBeenSet = createSelector(selectDataState, fromData.selectHasDataSourceBeenSet);
export const selectIsFromToc = createSelector(selectDataState, fromData.selectIsFromToc);
export const selectLastDataRefreshDateString = createSelector(
    selectDataState,
    fromData.selectLastDataRefreshDateString
);

/*
 * User Availability selectors
 */
export const selectUserAvailabilityState = createFeatureSelector<fromUserAvailability.AvailabilityState>(
    fromUserAvailability.userPresenceFeatureKey
);
export const selectUserPresenceReal = (id: string) =>
    createSelector(selectUserAvailabilityState, fromUserAvailability.selectUserPresence(id));
export const selectUserPresence = (id: string) => createSelector( // TakingDemoSourceIntoAccount
    selectUserPresenceReal(id),
    selectIsDemo,
    (presence, isDemo) => {
        if (isDemo) {
            //randomize availability for demo users
            //60% of the time the user is available
            //10% of the time the user is busy
            //15% of the time the user is away
            //5% of the time the user is do not disturb
            //10% of the time the user is unknown
            return {
                ...presence,
                availability: Math.random() < 0.6 ? 'Available' : Math.random() < 0.1 ? 'Busy' : Math.random() < 0.15 ? 'Away' : Math.random() < 0.05 ? 'DoNotDisturb' : 'Unknown'
            };
        }
        return presence;
    }
)
export const selectUserPresences = createSelector(
    selectUserAvailabilityState,
    fromUserAvailability.selectUserPresences
);
export const selectUserAvailability = createSelector(
    selectUserAvailabilityState,
    fromUserAvailability.selectUserAvailability
);

/**
 * Integrity Selectors
 */
export const selectReportConfigDataAndUsers = createSelector(
    integrityFeature.selectReportConfigData,
    selectUsersUnsorted,
    selectHasLoadedDataFromDb,
    (reportConfigData, users, hasLoaded) => {
        if (!reportConfigData || !users || !hasLoaded) {
            return null;
        }
        return {
            reportConfigData,
            users
        };
    }
);
export const selectIntegrityReportAndUsers = createSelector(
    selectUsersRaw, //ButFiltered,
    selectUserModifications,
    integrityFeature.selectReportResult,
    (users, userModifications, reportResult) => {
        if (users && userModifications && reportResult) {
            return {
                users,
                userModifications,
                reportResult
            };
        }
        return null;
    }
);

/**
 * Router Selectors
 */
export const selectRouterState = createFeatureSelector<fromRouter.RouterReducerState<RouterStateSnapshot>>('router');
const routerSelectors = fromRouter.getRouterSelectors();
export const selectRouteParam = routerSelectors.selectRouteParam;
export const selectUrl = routerSelectors.selectUrl;
export const selectQueryParam = (param: string) => {
    return createSelector(routerSelectors.selectQueryParam(param), (paramValue): string | undefined => {
        // If paramValue is an array, return the first element, otherwise return paramValue itself
        return Array.isArray(paramValue) ? paramValue[0] : paramValue;
    });
};
export const selectIsOnSharePointStartingUrl = createSelector(
    uiFeature.selectSharePointStartingUrl,
    selectUrl,
    (startingUrl, url) => {
        return `/directory/${encodeURI(startingUrl)}` === url;
    }
);
export const selectActiveView = createSelector(
    selectRouteParam('category'),
    selectRouteParam('slug'),
    (view, slug): ViewType => {
        //console.log('view', view);
        switch (view) {
            case '':
            case null:
            case undefined:
                return ViewType.People;
            case ViewType.Search:
                return ViewType.Search;
            case ViewType.Favorites:
                return ViewType.Favorites;
            default:
                if (slug != null) {
                    return ViewType.CategoryItem;
                }
                return ViewType.Category;
        }
    }
);
export const selectSlug = createSelector(selectRouteParam('slug'), (slug) => slug);
export const selectSearchString = createSelector(selectActiveView, selectSlug, (view, slug) => {
    //console.log('search string');
    //console.log(view, slug);
    if (view === ViewType.Search) {
        return slug;
    }
    return null;
});
export const selectSelectedPersonId = createSelector(selectQueryParam('userId'), (userId) => userId);
export const selectSettingsTabParam = createSelector(selectRouteParam('tab'), (tab) => tab);
export const selectSettingsTab = createSelector(selectSettingsTabParam, (tab) => {
    switch (tab) {
        case SettingsTab.General:
            return SettingsTab.General;
        case SettingsTab.Categories:
            return SettingsTab.Categories;
        case SettingsTab.Columns:
            return SettingsTab.Columns;
        case SettingsTab.Filters:
            return SettingsTab.Filters;
        case SettingsTab.Security:
            return SettingsTab.Security;
        case SettingsTab.Consent:
            return SettingsTab.Consent;
        case SettingsTab.DataSources:
            return SettingsTab.DataSources;
        default:
            return SettingsTab.General;
    }
});

//custom selectors
export const selectUserForProfile = createSelector(
    selectRouteParam('userId'),
    selectUsersUnsorted,
    selectUser,
    (userId, users, user) => {
        if (users == null || users.length === 0) {
            return null;
        }
        const userForProfileId = userId ?? user?.localAccountId;
        if (userForProfileId != null) {
            return users.find((x) => x.id === userForProfileId);
        }
        return null;
    }
);

//isTeams or isSharePoint
export const selectIsTeamsOrSharePoint = createSelector(
    selectIsTeams,
    uiFeature.selectHostedInSharePoint,
    (isTeams, isSharePoint) => {
        return isTeams || isSharePoint;
    }
);

export const selectActiveCategory = createSelector(
    selectActiveView,
    selectRouteParam('category'),
    selectSlug,
    selectCategories,
    (view, categoryName, slug, categories) => {
        if (view === ViewType.Category || view === ViewType.CategoryItem) {
            return categories.find((c) => c.slug === categoryName);
        }
    }
);

export const selectActiveCategoryItemsSorted = createSelector(
    selectActiveCategory,
    selectCategorySortFields,
    selectCategorySortOrders,
    (category, sortFields, sortOrders) => {
        if (category != null && category.items != null) {
            console.log('sortFields', sortFields);
            //sortFields is an object where keys are fieldNames of categories and values are sort field names
            const sortOrder = sortOrders[category.fieldName] ?? category.defaultSortOrder ?? SortOrder.Asc;
            if (sortOrder === SortOrder.Asc) {
                console.log('sortOrder asc', sortOrder);
            } else {
                console.log('sortOrder not equal asc', sortOrder);
            }
            if (sortOrder === SortOrder.Desc) {
                console.log('sortOrder dsc', sortOrder);
            } else {
                console.log('sortOrder not equal dsc', sortOrder);
            }
            const categorySortField = sortFields[category.fieldName] ?? category.defaultSortField ?? 'name';
            const colDef = category.columnDefinitions?.find((x) => x.name === categorySortField);
            if (colDef != null && colDef.type === ColumnType.ItemCount) {
                return [...category.items].sort((a, b) => {
                    if (a.people?.length < b.people?.length) {
                        //take sort order into account
                        return sortOrder === SortOrder.Asc ? -1 : 1;
                    }
                    if (a.people?.length > b.people?.length) {
                        return sortOrder === SortOrder.Asc ? 1 : -1;
                    }
                    return 0;
                });
            }
            if (categorySortField != null) {
                return [...category.items].sort((a, b) => {
                    if (a[categorySortField] < b[categorySortField]) {
                        //take sort order into account
                        return sortOrder === SortOrder.Asc ? -1 : 1;
                    }
                    if (a[categorySortField] > b[categorySortField]) {
                        return sortOrder === SortOrder.Asc ? 1 : -1;
                    }
                    return 0;
                });
            }
            return category.items;
        }
        return [];
    }
);

export const selectActiveCategoryItem = createSelector(selectActiveCategory, selectSlug, (category, slug) => {
    //debugger;
    if (category != null && slug != null && slug !== '') {
        return category.items.find((x) => x.name?.toLowerCase().trim() === slug.toLowerCase().trim());
    }
});

export const selectUsersSorted = createSelector(
    selectUsersUnsorted,
    selectSortField,
    selectSortOrder,
    (users, sortField, sortOrder) => {
        //debugger;
        return [...users].sort((a, b) => {
            if (getSortFieldValue(a, sortField) < getSortFieldValue(b, sortField)) {
                return sortOrder === SortOrder.Asc ? -1 : 1;
            }
            if (getSortFieldValue(a, sortField) > getSortFieldValue(b, sortField)) {
                return sortOrder === SortOrder.Asc ? 1 : -1;
            }
            return 0;
        });
    }
);

export const selectIsFromTocQueryParam = createSelector(selectQueryParam('fromTOC'), (fromTOC) => {
    //console.warn('selector');
    return fromTOC === '1';
});
export const selectChartId = createSelector(selectQueryParam('chartId'), (chartId) => chartId);

/**
 * Directory component selectors
 */
export const selectSearchResults = createSelector(
    selectUsersSorted,
    selectCategories,
    selectActiveCategoryItem,
    selectSearchString,
    selectUsersPageNumber,
    selectUsersPageSize,
    (users, categories, categoryItem, searchString, pageNumber, pageSize) => {
        //start timer
        const start = performance.now();
        if (searchString == null || searchString.length === 0) {
            return null;
        }

        //search all fields in users case insensitive and return the results
        // const filteredUsers = users.filter((user) => {
        //     const userString = JSON.stringify(user).toLowerCase();
        //     return userString.indexOf(searchString.toLowerCase()) !== -1;
        // });
        const filteredUsers = users.filter((user) => {
            return (
                (user.displayName != null &&
                    user.displayName.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) ||
                (user.mobilePhone != null &&
                    user.mobilePhone.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) ||
                (user.mail != null &&
                    user.mail.indexOf('@') !== -1 &&
                    user.mail.substring(0, user.mail.indexOf('@')).toLowerCase().indexOf(searchString.toLowerCase()) !==
                        -1) ||
                (user.faxNumber != null && user.faxNumber.toLowerCase().indexOf(searchString.toLowerCase()) !== -1)
            );
        });
        const categorySearchResults: CategorySearchResults[] = [];
        categories.forEach((category) => {
            const categorySearchResult: CategorySearchResults = {
                categoryId: category.id,
                name: category.name,
                slug: category.slug,
                icon: category.icon,
                items: []
            };
            category.items.forEach((item) => {
                if (item.name.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) {
                    categorySearchResult.items.push(item);
                }
            });
            if (categorySearchResult.items.length > 0) {
                categorySearchResults.push(categorySearchResult);
            }
        });
        const startItem = (pageNumber - 1) * pageSize;
        const endItem = pageNumber * pageSize;
        const result: SearchResults = {
            categorySearchResults,
            users: filteredUsers,
            usersPage: filteredUsers.slice(startItem, endItem)
        };
        //end timer
        const end = performance.now();
        console.log('search took', end - start);
        //log number of users found
        console.log('search results', result.users.length);
        return result;
    }
);

export const selectActiveCategoryItemUsers = createSelector(
    selectActiveCategoryItem,
    selectSortOrder,
    selectSortField,
    (categoryItem, sortOrder, sortField) => {
        if (categoryItem != null) {
            return [...categoryItem.people].sort((a, b) => {
                if (getSortFieldValue(a, sortField) < getSortFieldValue(b, sortField)) {
                    return sortOrder === SortOrder.Asc ? -1 : 1;
                }
                if (getSortFieldValue(a, sortField) > getSortFieldValue(b, sortField)) {
                    return sortOrder === SortOrder.Asc ? 1 : -1;
                }
                return 0;
            });
        }
        return [];
    }
);

export const selectActiveSetOfUsers = createSelector(
    selectUsersSorted,
    selectActiveView,
    selectActiveCategory,
    selectActiveCategoryItemUsers,
    selectFavorites,
    selectSearchResults,
    (users, view, category, categoryItemUsers, favorites, searchResults) => {
        if (view === ViewType.People) {
            return users;
        }
        if (view === ViewType.Favorites) {
            return users.filter((x) => favorites.includes(x.id));
        }
        if (view === ViewType.Category && category != null) {
            return [];
        }
        if (view === ViewType.CategoryItem) {
            return categoryItemUsers;
        }
        if (view === ViewType.Search) {
            return searchResults.users;
        }
        return [];
    }
);

export const selectActiveSetOfUsersFilteredByLetter = createSelector(
    selectActiveSetOfUsers,
    selectFilterLetter,
    (users, letter) => {
        if (letter == null || letter === '') {
            return users;
        }
        return users.filter((user) => {
            if (user.lastName == null) {
                return user.displayName?.toLowerCase().startsWith(letter.toLowerCase());
            }
            return user.lastName?.toLowerCase().startsWith(letter.toLowerCase());
        });
    }
);

export const selectActivePageOfUsers = createSelector(
    selectActiveSetOfUsersFilteredByLetter,
    selectUsersPageNumber,
    selectUsersPageSize,
    (users, pageNumber, pageSize) => {
        const startItem = (pageNumber - 1) * pageSize;
        const endItem = pageNumber * pageSize;
        return users.slice(startItem, endItem);
    }
);

export const selectDisplayedUsers = createSelector(
    selectActiveView,
    selectActiveCategory,
    selectActiveCategoryItem,
    selectSearchResults,
    selectActivePageOfUsers,
    selectGroupBy,
    selectActiveSetOfUsersFilteredByLetter,
    (view, category, categoryItem, searchResults, activePageOfUsers, groupBy, allUsers) => {
        //debugger;
        if (view === ViewType.People || view === ViewType.Favorites) {
            if (groupBy !== null) {
                return allUsers;
            }
            return activePageOfUsers;
        }
        if (view === ViewType.Category && category != null) {
            if (groupBy !== null) {
                return allUsers;
            }
            return activePageOfUsers;
        }
        if (view === ViewType.CategoryItem && categoryItem != null) {
            if (groupBy !== null) {
                return allUsers;
            }
            return activePageOfUsers;
        }
        if (view === ViewType.Search && searchResults != null) {
            if (groupBy !== null) {
                return allUsers;
            }
            return activePageOfUsers;
        }
    }
);

export const selectDisplayedUsersForCalendar = createSelector(
    selectActiveView,
    selectActiveCategory,
    selectActiveCategoryItem,
    selectSearchResults,
    selectActiveSetOfUsers,
    (view, category, categoryItem, searchResults, allUsers) => {
        if (view === ViewType.People) {
            return allUsers;
        }
        if (view === ViewType.Category && category != null) {
            return allUsers;
        }
        if (view === ViewType.CategoryItem && categoryItem != null) {
            return allUsers;
        }
        if (view === ViewType.Search && searchResults != null) {
            return searchResults.users;
        }
    }
);

export const selectUsersGroups = createSelector(
    selectDisplayedUsers,
    selectGroupBy,
    selectCategories,
    (users, groupBy, categories) => {
        if (groupBy === null || users === null) {
            return [];
        }
        //group users by field
        const category = categories.find((x) => x.fieldName === groupBy);
        const groups: DisplayedUsersGroup[] = [];
        users.reduce<DisplayedUsersGroup[]>((groups, user, index, arr) => {
            const group = groups.find((x) => x.fieldValue === user[groupBy]);
            if (group == null) {
                //prev.push();
                const categoryItem = category?.items.find((x) => x.fieldValue === user[groupBy]);
                const newGroup: DisplayedUsersGroup = {
                    title: categoryItem?.name ?? user[groupBy] + ' (' + groupBy + ')',
                    fieldValue: user[groupBy],
                    fieldName: groupBy,
                    categoryItem: category?.items.find((x) => x.fieldValue === user[groupBy]),
                    users: [user],
                    originalUsersCount: 1
                };
                groups.push(newGroup);
            } else {
                group.users.push(user);
                group.originalUsersCount = group.users.length;
            }
            return groups;
        }, groups);
        return groups;
    }
);

export const selectDisplayedUsersGroups = createSelector(
    selectUsersGroups,
    selectUsersPageNumber,
    selectUsersPageSize,
    (groups, pageNumber, pageSize) => {
        if (groups == null) {
            return [];
        }
        const users = groups.reduce((acc, group) => [...acc, ...group.users], []);
        const startIndex = (pageNumber - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedUsers = users.slice(startIndex, endIndex);

        const paginatedGroups: DisplayedUsersGroup[] = paginatedUsers.reduce((acc, user) => {
            const group = groups.find((group) => group.users.includes(user));
            const existing = acc.find((x) => x.fieldValue === group.fieldValue);
            if (existing == null) {
                acc.push({
                    ...group,
                    users: [user]
                });
            } else {
                existing.users.push(user);
            }
            return acc;
        }, []);
        return paginatedGroups;
    }
);

export const selectDisplayedCount = createSelector(
    selectActiveView,
    selectActiveSetOfUsersFilteredByLetter,
    selectSearchResults,
    selectFavorites,
    (view, users, searchResults, favorites) => {
        if (view === ViewType.People) {
            return users.length;
        }
        if (view === ViewType.Favorites) {
            return favorites.length;
        }
        if (view === ViewType.CategoryItem) {
            return users.length;
        }
        if (view === ViewType.Search) {
            return users.length;
        }
        return 0;
    }
);

export const selectTenantDataSourceCheckData = createSelector(
    selectTenantAccount,
    selectUserIsAdmin,
    selectIsFromToc,
    selectSelectedDataSourceChartId,
    selectSelectedDataSourceId,
    selectIsDemo,
    (tenantAccount, isAdmin, isFromToc, chartId, dataSourceId, isDemo) => {
        if (tenantAccount == null) {
            return null;
        }
        return {
            tenantAccount,
            isAdmin,
            isFromToc,
            chartId,
            dataSourceId,
            isDemo
        };
    }
);

export const selectDbServiceDataSourceData = createSelector(
    selectUser,
    selectIsFromToc,
    selectRouterState,
    selectSelectedDataSourceChartId,
    selectHasDataSourceBeenSet,
    (user, isFromToc, routerState, chartId, hasDataSourceBeenSet) => {
        if (!hasDataSourceBeenSet || routerState == null || user == null) {
            return null;
        }
        return {
            user,
            isFromToc,
            chartId
        };
    }
);
