import * as t from "../artifactsForCDN/validDomains.json.js";
const i = "2.0.1",
  s = "2.0.2",
  e = "2.0.3",
  n = "2.0.4",
  o = "2.0.1",
  a = "1.9.0",
  r = "2.0.0",
  m = "1.7.0",
  l = "1.8.0",
  d = "2.0.0",
  h = "1.9.0",
  p = t.validOrigins,
  c = new URL("https://res.cdn.office.net/teams-js/validDomains/json/validDomains.json"),
  f = /^https:\/\//,
  j = "https",
  v = "teams.microsoft.com",
  y = "The library has not yet been initialized",
  D = "The runtime has not yet been initialized",
  T = "The runtime version is not supported",
  b = "The call was not properly started";
export { m as captureImageMobileSupportVersion, i as defaultSDKVersionForCompatCheck, b as errorCallNotStarted, y as errorLibraryNotInitialized, D as errorRuntimeNotInitialized, T as errorRuntimeNotSupported, d as getMediaCallbackSupportVersion, o as getUserJoinedTeamsSupportedAndroidClientVersion, n as imageOutputFormatsAPISupportVersion, a as locationAPIsRequiredVersion, l as mediaAPISupportVersion, e as nonFullScreenVideoModeAPISupportVersion, r as peoplePickerRequiredVersion, h as scanBarCodeAPIMobileSupportVersion, v as teamsDeepLinkHost, j as teamsDeepLinkProtocol, f as userOriginUrlValidationRegExp, c as validOriginsCdnEndpoint, p as validOriginsFallback, s as videoAndImageMediaAPISupportVersion };